import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AppointmentModal from './AppointmentModal';
import ScheduleCell from './ScheduleCell';
import './SchedulerGrid.css';

const people = ['Alice', 'Bob', 'Charlie', 'Diana'];
const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = 9 + Math.floor(i / 2);
    const minutes = i % 2 === 0 ? '00' : '30';
    return `${hour}:${minutes} ${hour < 12 ? 'AM' : 'PM'}`;
});

const getAppointmentCells = (appointments, person, hour) => {
    return appointments.filter(app =>
        app.person === person && isTimeInRange(hour, app.startHour, app.endHour)
    );
};

const timeToMinutes = (time) => {
    const [timePart, period] = time.split(' ');
    const [hourStr, minuteStr] = timePart.split(':');
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);

    if (period === 'PM' && hour !== 12) {
        hour += 12;
    } else if (period === 'AM' && hour === 12) {
        hour = 0;
    }

    return hour * 60 + minute;
};

const isTimeInRange = (time, start, end) => {
    const timeValue = timeToMinutes(time);
    const startValue = timeToMinutes(start);
    const endValue = timeToMinutes(end);
    return timeValue >= startValue && timeValue < endValue;
};

function SchedulerGrid() {
    const [selectedCell, setSelectedCell] = useState(null);
    const [editingAppointment, setEditingAppointment] = useState(null);
    const [appointments, setAppointments] = useState([
        { person: 'Alice', startHour: '9:00 AM', endHour: '10:00 AM', name: 'Meeting with Bob' },
        { person: 'Bob', startHour: '10:30 AM', endHour: '11:30 AM', name: 'Project Work' },
      ]);

    const handleCellClick = (person) => {
        setSelectedCell({ person });
        setEditingAppointment(null);
    };

    const handleEditAppointment = (appointment) => {
        setSelectedCell({ person: appointment.person });
        setEditingAppointment(appointment);
    };

    const handleAppointmentSave = (appointment) => {
        setAppointments((prevAppointments) => {
            if (editingAppointment) {
                return prevAppointments.map((app) =>
                    app.person === editingAppointment.person && app.startHour === editingAppointment.startHour
                        ? appointment
                        : app
                );
            } else {
                return [...prevAppointments, appointment];
            }
        });
        setSelectedCell(null);
        setEditingAppointment(null);
    };

    const moveAppointment = (draggedAppointment, targetPerson, targetHour) => {
        setAppointments((prevAppointments) => {
            const filteredAppointments = prevAppointments.filter(
                appointment => !(appointment.person === draggedAppointment.person && appointment.startHour === draggedAppointment.startHour)
            );
            return [...filteredAppointments, { ...draggedAppointment, person: targetPerson, startHour: targetHour }];
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="scheduler-grid">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {hours.map((hour) => (
                                <th key={hour}>{hour}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {people.map((person) => (
                            <tr key={person}>
                                <td>{person}</td>
                                {hours.map((hour) => {
                                    const appointmentsInCell = getAppointmentCells(appointments, person, hour);
                                    return (
                                        <ScheduleCell
                                            key={hour}
                                            person={person}
                                            hour={hour}
                                            appointment={appointmentsInCell.length > 0 ? appointmentsInCell[0] : null}
                                            onDrop={(draggedAppointment) => moveAppointment(draggedAppointment, person, hour)}
                                            onClick={() => appointmentsInCell.length > 0 ? handleEditAppointment(appointmentsInCell[0]) : handleCellClick(person)}
                                        />
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {selectedCell && (
                    <AppointmentModal
                        person={selectedCell.person}
                        startHour={editingAppointment?.startHour || ''}
                        endHour={editingAppointment?.endHour || ''}
                        appointment={editingAppointment}
                        onSave={handleAppointmentSave}
                        onClose={() => { setSelectedCell(null); setEditingAppointment(null); }}
                    />
                )}
            </div>
        </DndProvider>
    );
}

export default SchedulerGrid;
