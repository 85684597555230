import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-modal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PlusSquareIcon } from "@chakra-ui/icons";
import "./index.css";
// import AppointmentModal from "./appointmentModal";
import AddAppointmentModal from "../DaySheet6/appointmentModal";
import AppointmentCardModal from "./appointmentCard";
import ConfirmationModal from "./confirmationPopup";
const localizer = momentLocalizer(moment);

const DefaultEvents = [
  {
    title: "Rishabh ",
    start: new Date(2024, 7, 18, 9, 0, 0),
    end: new Date(2024, 7, 18, 10, 0, 0),
    doctor: "Dr. Jane Doe",
  },
  {
    title: "Vishwas",
    start: new Date(2024, 7, 18, 10, 30, 0),
    end: new Date(2024, 7, 18, 11, 0, 0),
    id: 1,
    doctor: "Dr. Shailesh",
  },
  {
    title: "Patient Name",
    start: new Date(2024, 7, 18, 11, 30, 0),
    end: new Date(2024, 7, 18, 11, 50, 0),
    id: 2,
    doctor: "Dr. D Parikh",
  },
  {
    title: "Patient Name",
    start: new Date(2024, 7, 18, 12, 30, 0),
    end: new Date(2024, 7, 18, 1, 0, 0),
    id: 3,
    doctor: "Dr. Priyanka",
  },
  {
    title: "Patient Name",
    start: new Date(2024, 7, 18, 14, 30, 0),
    end: new Date(2024, 7, 18, 14, 50, 0),
    id: 4,
    doctor: "Dr. Anuj",
  },
  {
    title: "Patient Name",
    start: new Date(2024, 7, 18, 15, 30, 0),
    end: new Date(2024, 7, 18, 10, 50, 0),
    id: 5,
    doctor: "Dr. Tarun",
  },
];

const CustomHeader = ({ label, doctorNames }) => {
  return (
    <div className="calendar-header">
      <div className="time-slot-header">{label}</div>
      {DefaultEvents.map((doctor, index) => (
        <div
          key={index}
          className="doctor-header"
          style={{ width: "80px", textAlign: "center", padding: "5px" }}
        >
          {doctor.doctor}
        </div>
      ))}
    </div>
  );
};

const CalendarComponent3 = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({});
  const [events, setEvents] = useState(DefaultEvents);
  const [startDate, setStartDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAppCardOpen, setIsAppCardOpen] = useState(false);
  const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);
  const handleSelectSlot = (slotInfo) => {
    setNewEvent({
      start: slotInfo.start,
      end: slotInfo.end,
      title: "",
    });
    setModalIsOpen(true);
    setIsModalOpen(true);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
    setIsAppCardOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    // Implement logic to save the new event to your data source
    // You'll likely need to make an API call or update your local state
    setModalIsOpen(false);
  };

  const moveEvent = ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    setEvents(nextEvents);
  };

  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    setEvents(nextEvents);
  };
  const doctorNames = ['Dr. Smith', 'Dr. Johnson', 'Dr. Williams'];
  return (
    <div className="daysheet-wrapper" style={{ padding: "42px 22px" }}>
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            border: "2px solid #f6f6f6",
            padding: "12px 10px",
            marginBottom: "12px",
          }}
        >
          <div
            style={{
              borderRadius: "8px",
              border: "1px solid #747474",
              overflow: "hidden",
              padding: "2px 4px",
            }}
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div>
            <span className="add-sq-container">
              <PlusSquareIcon onClick={() => setIsModalOpen(true)} />
            </span>
          </div>
        </div>
        <div className="calendar-container">
          {/* <div className="calendar-header">
            <div className="time-slot-header">Time</div>
            {DefaultEvents.map((doctor, index) => (
              <div
                key={index}
                className="doctor-header"
                style={{ width: "80px" }}
              >
                {doctor.doctor}
              </div>
            ))}
          </div> */}
          <Calendar
            selectable
            localizer={localizer}
            events={events}
            defaultView="day"
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            resizable
            style={{ height: "70vh" }}
            toolbar={false}
            views={["day"]}
            components={{
              timeGutterHeader: () => <CustomHeader label="Doctor" doctorNames={doctorNames} />, // Use custom header
              event: ({ event }) => (
                <div
                  className="calendar-event"
                  style={{ width: "80px" }}
                  data-doctor={event.doctor}
                >
                  <div className="event-title">{event.title}</div>
                  <div className="event-time">
                    {moment(event.start).format("h:mm A")} -{" "}
                    {moment(event.end).format("h:mm A")}
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <AddAppointmentModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        <AppointmentCardModal
          isOpen={isAppCardOpen}
          onClose={() => setIsAppCardOpen(false)}
          setIsConfirmationPopup={setIsConfirmationPopup}
        />
        <ConfirmationModal
          isOpen={isConfirmationPopup}
          onClose={() => setIsConfirmationPopup(false)}
        />
      </DndProvider>
    </div>
  );
};

export default CalendarComponent3;
