import axios from 'axios';
import { toast } from 'react-toastify';

// Create an Axios instance with default configurations
console.log("process.env.REACT_APP_API_ENDPOINT", process.env.REACT_APP_API_ENDPOINT);
const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT, // Replace with your API base URL
  timeout: 10000, // Request timeout
  headers: {
    'Content-Type': 'application/json',
    'AgentName': process.env.REACT_APP_API_AGENT_NAME, 
    'X-APIKEY': process.env.REACT_APP_API_X_APIKEY, 
    "ngrok-skip-browser-warning": "69420"
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login"
    }
    // Handle errors globally
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error('Response error:', error.response.data);
      toast.error(error.response.data)
    } else if (error.request) {
      // Request was made but no response received
      console.error('Request error:', error.request);
      toast.error(error.request)
    } else {
      // Something else caused an error
      console.error('Error:', error.message);
      toast.error(error.message)
    }
    return Promise.reject(error);
  }
);

export default api;
