import { Icon } from '@chakra-ui/react'

function BackArrowIcon({ color = "black" }) {
  return (
    <Icon fontSize={24} color={color} mt={"10px"}>
      <path id="Vector" d="M18.0006 8.00042C18.0006 8.19933 17.9216 8.3901 17.7809 8.53075C17.6403 8.6714 17.4495 8.75042 17.2506 8.75042H2.5609L8.03122 14.2198C8.1009 14.2895 8.15617 14.3722 8.19389 14.4632C8.2316 14.5543 8.25101 14.6519 8.25101 14.7504C8.25101 14.849 8.2316 14.9465 8.19389 15.0376C8.15617 15.1286 8.1009 15.2114 8.03122 15.281C7.96153 15.3507 7.87881 15.406 7.78776 15.4437C7.69672 15.4814 7.59914 15.5008 7.50059 15.5008C7.40204 15.5008 7.30446 15.4814 7.21342 15.4437C7.12237 15.406 7.03965 15.3507 6.96996 15.281L0.219965 8.53104C0.150233 8.46139 0.0949134 8.37867 0.0571702 8.28762C0.019427 8.19657 0 8.09898 0 8.00042C0 7.90186 0.019427 7.80426 0.0571702 7.71321C0.0949134 7.62216 0.150233 7.53945 0.219965 7.46979L6.96996 0.719792C7.1107 0.579062 7.30157 0.5 7.50059 0.5C7.69961 0.5 7.89048 0.579062 8.03122 0.719792C8.17195 0.860523 8.25101 1.05139 8.25101 1.25042C8.25101 1.44944 8.17195 1.64031 8.03122 1.78104L2.5609 7.25042H17.2506C17.4495 7.25042 17.6403 7.32943 17.7809 7.47009C17.9216 7.61074 18.0006 7.8015 18.0006 8.00042Z" fill="black" />
    </Icon>
  )
}

export default BackArrowIcon