import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Box,
    Select,
    Menu,
    MenuButton,
    Button,
    MenuItem,
    MenuList,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";
import { ChevronDownIcon, SmallCloseIcon } from "@chakra-ui/icons";
import './appointmentInfoModel.css'
import ClockIcon from "../icons/ClockIcon";
import CalendarBlank from "../icons/CalendarBlank";
import StethoscopeGrey from "../icons/StethoscopeGrey";
import ChatCentered from "../icons/ChatCentered";
import Ellipse46 from "../icons/Ellipse46";
import ConfirmationModal from "./confirmationPopup";

const AppointmentInfoModel = ({ isOpen, onClose, selectedCell, handleDelete, setOpenModel, setIsEdit, handleUpdateStatus }) => {
    const [openConfirmModel, setOpenConfirmModel] = useState(false);
    const [statusDetails, setStatusDetails] = useState([
        { title: 'No Show/Resechedule', bg: '#FFCFCB', c: '#CA2014', bgi: '#F44336', status: 'noshowresechedule' },
        { title: 'Waiting', bg: '#E3D7F0', c: '#6434D2', bgi: '#6434D2', status: 'waiting' },
        { title: 'Late/Reschedule', bg: '#FDE6C4', c: '#FF4D19', bgi: '#FF9800', status: 'latereschedule' },
        { title: 'Reschedule/done', bg: '#CAE8FF', c: '#03589C', bgi: '#2196F3', status: 'rescheduledone' },
        { title: 'In progress', bg: '#FFFBD5', c: '#A38109', bgi: '#FFEB3B', status: 'inprogress' },
        { title: 'Waiting', bg: '#FFFBD5', c: '#A38109', bgi: '#FFEB3B', status: 'waiting' },
        { title: 'Completed', bg: '#DCFCE7', c: '#14532D', bgi: '#009688', status: 'completed' },
        { title: 'Canceled', bg: '#E3E3E3', c: '#2B2829', bgi: '#3D3D3D', status: 'canceled' },
        { title: 'No Show', bg: '#E3E3E3', c: '#2B2829', bgi: '#3D3D3D', status: 'noshow' },
    ]);
    const [selectedStatus, setSelectedStatus] = useState({ title: 'Waiting', bg: '#FFFBD5', c: '#A38109', bgi: '#FFEB3B', status: 'waiting'});

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const d = new Date(selectedCell?.date);
    const month = months[d.getMonth()];
    const day = days[d.getDay()];

    useEffect(() => {
        let appointmentStatus = statusDetails.filter((item) => item.status === selectedCell.appointmentStatus);
        if (appointmentStatus.length) {
            setSelectedStatus(appointmentStatus[0])
        }
    }, [])

    const handleDeleteConfirm = () => {
        setOpenConfirmModel(false);
        handleDelete();
    }

    const handleChange = (item) => {
        setSelectedStatus(item);
        handleUpdateStatus(item.status)
    }

    useEffect(() => {
      if(selectedCell?.appointmentStatus){
        const foundStatus = statusDetails.find(item => item.status === selectedCell.appointmentStatus);
        // If the status exists, use it. If not, set a default value.
        setSelectedStatus(foundStatus || { title: 'Waiting', bg: '#FFFBD5', c: '#A38109', bgi: '#FFEB3B', status: 'waiting' });
      }else{
        setSelectedStatus({ title: 'No Show', bg: '#E3E3E3', c: '#2B2829', bgi: '#3D3D3D', status: 'noshow' });
      }
    }, [selectedCell])
    

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                {/* {!openConfirmModel && (<ModalOverlay />)} */}
                <ModalOverlay />
                <ModalContent className='appointment-info-model' style={{
                    backgroundColor: selectedCell && selectedCell.bg ? selectedCell.bg : '#D8EAF6'
                }}>
                    <ModalHeader className="appointment-info-header">
                        <div className="appointment-info-header-icons" onClick={() => { setOpenModel(true); setIsEdit(true) }}>
                            <EditIcon />
                        </div>
                        <div className="appointment-info-header-icons" onClick={() => setOpenConfirmModel(true)}>
                            <DeleteIcon />
                        </div>
                        <div className="appointment-info-header-icons">
                            <SmallCloseIcon onClick={onClose} />
                        </div>
                    </ModalHeader>
                    <ModalBody className="appointment-body">
                        <Box display="flex" flexDirection="column" w={"100%"} h={"100%"}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="info-name-icon" style={{ background: selectedCell && selectedCell.bg ? selectedCell.bg : '#D8EAF6' }}></div>
                                    <div className="info-name"> {selectedCell.patientName} </div>
                                </div>

                                <div className="info-patient-id">Patient ID - SDC 001</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div> <ClockIcon /> </div>
                                    <div className="info-time"> {selectedCell.start} - {selectedCell.end} </div>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div><CalendarBlank /></div>
                                    <div className="info-time"> {day}, {month}, {d.getFullYear()}</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div> <StethoscopeGrey /> </div>
                                    <div className="info-time"> {selectedCell.person} </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div> <ChatCentered /> </div>
                                    <div className="info-time"> {selectedCell.remarks ? selectedCell.remarks : "-"} </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div><Ellipse46 color={selectedStatus.bgi} /></div>
                                    <div className="info-time"> Status </div>
                                    <div className="info-time">
                                        <div className="status-div" style={{ backgroundColor: selectedStatus.bg, color: selectedStatus.c }}>
                                            <p className="status-doc" style={{ backgroundColor: selectedStatus.bgi }}></p>
                                            <p>{selectedStatus.title}</p>
                                        </div>
                                    </div>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}></MenuButton>
                                        <MenuList>
                                            {statusDetails.map((item, i) => {
                                                return <MenuItem onClick={() => handleChange(item)}>{item.title}</MenuItem>
                                            })}
                                        </MenuList>
                                    </Menu>
                                </div>
                            </div>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {openConfirmModel &&
                <ConfirmationModal
                    isOpen={openConfirmModel}
                    onClose={() => setOpenConfirmModel(false)}
                    onYes={handleDeleteConfirm}
                />
            }
        </>
    );
};

export default AppointmentInfoModel;
