import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react'

function ToothMappingSingle({ id, name, addOrRemoveSelectedMissing, selectedMissing = [], isAphla }) {
  const [missingInfo, setMissingInfo] = useState({
    id: id,
    name: name,
    data: [
      [
        [
          { valueInt: "8", valueAlpha: "", selected: false },
          { valueInt: "7", valueAlpha: "", selected: false },
          { valueInt: "6", valueAlpha: "", selected: false },
          { valueInt: "5", valueAlpha: "E", selected: false },
          { valueInt: "4", valueAlpha: "D", selected: false },
          { valueInt: "3", valueAlpha: "C", selected: false },
          { valueInt: "2", valueAlpha: "B", selected: false },
          { valueInt: "1", valueAlpha: "A", selected: false },
        ],
        [
          { valueInt: "1", valueAlpha: "A", selected: false },
          { valueInt: "2", valueAlpha: "B", selected: false },
          { valueInt: "3", valueAlpha: "C", selected: false },
          { valueInt: "4", valueAlpha: "D", selected: false },
          { valueInt: "5", valueAlpha: "E", selected: false },
          { valueInt: "6", valueAlpha: "", selected: false },
          { valueInt: "7", valueAlpha: "", selected: false },
          { valueInt: "8", valueAlpha: "", selected: false },
        ],
      ],
      [
        [
          { valueInt: "8", valueAlpha: "", selected: false },
          { valueInt: "7", valueAlpha: "", selected: false },
          { valueInt: "6", valueAlpha: "", selected: false },
          { valueInt: "5", valueAlpha: "E", selected: false },
          { valueInt: "4", valueAlpha: "D", selected: false },
          { valueInt: "3", valueAlpha: "C", selected: false },
          { valueInt: "2", valueAlpha: "B", selected: false },
          { valueInt: "1", valueAlpha: "A", selected: false },
        ],
        [
          { valueInt: "1", valueAlpha: "A", selected: false },
          { valueInt: "2", valueAlpha: "B", selected: false },
          { valueInt: "3", valueAlpha: "C", selected: false },
          { valueInt: "4", valueAlpha: "D", selected: false },
          { valueInt: "5", valueAlpha: "E", selected: false },
          { valueInt: "6", valueAlpha: "", selected: false },
          { valueInt: "7", valueAlpha: "", selected: false },
          { valueInt: "8", valueAlpha: "", selected: false },
        ],
      ]
    ]
  });

  return (
    <>
      {missingInfo && (
        <Box flex={1} p={2}>
          <Text mb={"8px"} fontWeight="bold">{name}</Text>
          <Box border={"1px solid #4C8BF5"} borderRadius={"8px"} w={"100%"}>
            {missingInfo.data.map((missInfo, parentIndex) => {
              let row = parentIndex === 0 ? "upperRow" : "lowerRow";
              return (
                <Flex flexDir={"row"} w={"100%"}>
                  {missInfo.map((info, idx) => {
                    let childRow = idx === 0 ? "Left" : "Right";
                    return (
                      <Flex justifyContent={"space-evenly"} w={"100%"}>
                        {info.map((i, index) => {
                          let intKey = `${id}-${row}${childRow}-${i.valueInt}`;
                          let alphaKey = `${id}-${row}${childRow}-${i.valueAlpha}`;
                          let intKeyIncluded = selectedMissing.includes(intKey);
                          let alphaKeyIncluded = selectedMissing.includes(alphaKey);
                          let bothSelected = intKeyIncluded && alphaKeyIncluded;
                          return (
                            <>
                              <Flex
                                // p={"4px"}
                                m={"2px"}
                                gap={"4px"}
                                flexDir={"column"}
                                justifyContent={row === "upperRow" ? "start" : "end"}
                                alignItems={"center"}
                                borderRight={""}
                                cursor={"pointer"}
                                outlineOffset={-3}
                                borderRadius={"4px"}
                                color={bothSelected ? "black" : "#939393"}
                                background={bothSelected ? "rgba(76, 139, 245, 0.27)" : ""}
                                border={bothSelected? "1px solid #4C8BF5" : ""}
                                onDoubleClick={() => {
                                  if (i.valueAlpha) {
                                    addOrRemoveSelectedMissing(
                                      true, // isDoubleClick
                                      intKey,
                                      alphaKey,
                                    )
                                  } else {
                                    addOrRemoveSelectedMissing(
                                      true, // isDoubleClick
                                      intKey
                                    )
                                  }
                                }}
                              >
                                {row === "upperRow" && <Text fontSize={"12px"}
                                  fontWeight={intKeyIncluded ? 900 : 500}
                                  color={intKeyIncluded ? "black" : "#939393"}
                                  background={intKeyIncluded && !bothSelected ? "rgba(76, 139, 245, 0.27)" : ""}
                                  border={intKeyIncluded && !bothSelected ? "1px solid #4C8BF5" : ""}
                                  padding={"4px"}
                                  borderRadius={"4px"}

                                  onClick={() => {
                                    addOrRemoveSelectedMissing(
                                      false,
                                      intKey,
                                    )
                                  }}
                                >
                                  {i.valueInt}
                                </Text>}
                                {isAphla && row !== "upperRow" && i.valueAlpha && isAphla && <Text fontSize={"12px"}
                                  fontWeight={alphaKeyIncluded ? 900 : 500}
                                  color={alphaKeyIncluded ? "black" : "#939393"}
                                  border={alphaKeyIncluded && !bothSelected ? "1px solid #4C8BF5" : ""}
                                  padding={"4px"}
                                  borderRadius={"4px"}
                                  background={alphaKeyIncluded && !bothSelected ? "rgba(76, 139, 245, 0.27)" : ""}
                                  onClick={() => {
                                    addOrRemoveSelectedMissing(
                                      false,
                                      alphaKey,
                                    )
                                  }}
                                >
                                  {i.valueAlpha}
                                </Text>}
                                {/* <Box
                                  key={index}
                                  border="1.4px solid #2C2C2C"
                                  w={"13px"}
                                  h={"13px"}
                                  borderRadius="50%"
                                  background={selectedMissing.includes(intKey) ? "black" : ""}
                                >
                                </Box> */}
                                {isAphla && row === "upperRow" && i.valueAlpha && isAphla && <Text fontSize={"12px"}
                                  fontWeight={alphaKeyIncluded ? 900 : 500}
                                  color={alphaKeyIncluded ? "black" : "#939393"}
                                  border={alphaKeyIncluded && !bothSelected ? "1px solid #4C8BF5" : ""}
                                  padding={"4px"}
                                  borderRadius={"4px"}
                                  background={alphaKeyIncluded && !bothSelected ? "rgba(76, 139, 245, 0.27)" : ""}
                                  onClick={() => {
                                    addOrRemoveSelectedMissing(
                                      false,
                                      alphaKey,
                                    )
                                  }}
                                >
                                  {i.valueAlpha}
                                </Text>}
                                {row !== "upperRow" && <Text fontSize={"12px"}
                                  fontWeight={intKeyIncluded ? 900 : 500}
                                  color={intKeyIncluded ? "black" : "#939393"}
                                  border={intKeyIncluded && !bothSelected ? "1px solid #4C8BF5" : ""}
                                  padding={"4px"}
                                  borderRadius={"4px"}
                                  background={intKeyIncluded && !bothSelected ? "rgba(76, 139, 245, 0.27)" : ""}
                                  onClick={() => {
                                    addOrRemoveSelectedMissing(
                                      false,
                                      intKey,
                                    )
                                  }}
                                >
                                  {i.valueInt}
                                </Text>}
                              </Flex>
                              {idx === 0 && index === 7 && <Box w={"0.5px"} h={"100%"} ml={"5px"} borderRight={"1px solid #4C8BF5"}></Box>}
                            </>
                          )
                        })}
                      </Flex>
                    )
                  })}
                </Flex>
              )
            }
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default ToothMappingSingle