import { Box } from '@chakra-ui/react'
import React from 'react'
import Topbar from '../Topbar'
import PatientForm from './PatientForm'

function AddPatient() {
  return (
    <Box display="flex" flexDirection="column" w={"100%"} h={"100vh"} bg={"#F8FAFF"}>
      <Box
        as="header"
      >
        <Topbar title="Patients" />
      </Box>

      {/* Title Searchbar and Add Patient Button */}
      <Box m={"0 16px 16px"} borderRadius="8px 8px 0px 0px" boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"} bg={"white"}>
        <PatientForm />
      </Box>
    </Box>
  )
}

export default AddPatient