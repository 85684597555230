import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './SchedulerGrid.css';
import { Box, Input } from '@chakra-ui/react';
import AddAppointmentModal from './appointmentModal';
import Topbar from '../../features/dashboard/Topbar';
import { InfoIcon, PlusSquareIcon } from '@chakra-ui/icons';
import AppointmentInfoModel from './appointmentInfoModel';
import api from '../../api';
import { toast } from 'react-toastify';
import RescheduledModel from './rescheduledPopup';

const ItemTypes = {
    APPOINTMENT: 'appointment',
};

const people = ['Doctor 1', 'Doctor 2', 'Doctor 3', 'Doctor 4', 'Doctor 5', 'Doctor 6'];
// const people = ['Doctor 1', 'Doctor 2', 'Doctor 3'];
const showTimes = [
    '12:00 AM - 01:00 AM',
    '01:00 AM - 02:00 AM',
    '02:00 AM - 03:00 AM',
    '03:00 AM - 04:00 AM',
    '04:00 AM - 05:00 AM',
    '05:00 AM - 06:00 AM',
    '06:00 AM - 07:00 AM',
    '07:00 AM - 08:00 AM',
    '08:00 AM - 09:00 AM',
    '09:00 AM - 10:00 AM',
    '10:00 AM - 11:00 AM',
    '11:00 AM - 12:00 PM',
    '12:00 PM - 01:00 PM',
    '01:00 PM - 02:00 PM',
    '02:00 PM - 03:00 PM',
    '03:00 PM - 04:00 PM',
    '04:00 PM - 05:00 PM',
    '05:00 PM - 06:00 PM',
    '06:00 PM - 07:00 PM',
    '07:00 PM - 08:00 PM',
    '08:00 PM - 09:00 PM',
    '09:00 PM - 10:00 PM',
    '10:00 PM - 11:00 PM',
    '11:00 PM - 12:00 AM',
];

const slots = [
    '12:00 - 12:15', '12:16 - 12:30', '12:31 - 12:45', '12:46 - 1:00',
    '1:00 - 1:15', '1:16 - 1:30', '1:31 - 1:45', '1:46 - 2:00',
    '2:00 - 2:15', '2:16 - 2:30', '2:31 - 2:45', '2:46 - 3:00',
    '3:00 - 3:15', '3:16 - 3:30', '3:31 - 3:45', '3:46 - 4:00',
    '4:00 - 4:15', '4:16 - 4:30', '4:31 - 4:45', '4:46 - 5:00',
    '5:00 - 5:15', '5:16 - 5:30', '5:31 - 5:45', '5:46 - 6:00',
    '6:00 - 6:15', '6:16 - 6:30', '6:31 - 6:45', '6:46 - 7:00',
    '7:00 - 7:15', '7:16 - 7:30', '7:31 - 7:45', '7:46 - 8:00',
    '8:00 - 8:15', '8:16 - 8:30', '8:31 - 8:45', '8:46 - 9:00',
    '9:00 - 9:15', '9:16 - 9:30', '9:31 - 9:45', '9:46 - 10:00',
    '10:00 - 10:15', '10:16 - 10:30', '10:31 - 10:45', '10:46 - 11:00',
    '11:00 - 11:15', '11:16 - 11:30', '11:31 - 11:45', '11:46 - 12:00',
    '12:00 - 12:15', '12:16 - 12:30', '12:31 - 12:45', '12:46 - 1:00',
    '1:00 - 1:15', '1:16 - 1:30', '1:31 - 1:45', '1:46 - 2:00',
    '2:00 - 2:15', '2:16 - 2:30', '2:31 - 2:45', '2:46 - 3:00',
    '3:00 - 3:15', '3:16 - 3:30', '3:31 - 3:45', '3:46 - 4:00',
    '4:00 - 4:15', '4:16 - 4:30', '4:31 - 4:45', '4:46 - 5:00',
    '5:00 - 5:15', '5:16 - 5:30', '5:31 - 5:45', '5:46 - 6:00',
    '6:00 - 6:15', '6:16 - 6:30', '6:31 - 6:45', '6:46 - 7:00',
    '7:00 - 7:15', '7:16 - 7:30', '7:31 - 7:45', '7:46 - 8:00',
    '8:00 - 8:15', '8:16 - 8:30', '8:31 - 8:45', '8:46 - 9:00',
    '9:00 - 9:15', '9:16 - 9:30', '9:31 - 9:45', '9:46 - 10:00',
    '10:00 - 10:15', '10:16 - 10:30', '10:31 - 10:45', '10:46 - 11:00',
    '11:00 - 11:15', '11:16 - 11:30', '11:31 - 11:45', '11:46 - 12:00',
];

const slotsTooltip = [
    '12:00 AM - 12:15 AM', '12:16 AM - 12:30 AM', '12:31 AM - 12:45 AM', '12:46 AM - 1:00 AM',
    '1:00 AM - 1:15 AM', '1:16 AM - 1:30 AM', '1:31 AM - 1:45 AM', '1:46 AM - 2:00 AM',
    '2:00 AM - 2:15 AM', '2:16 AM - 2:30 AM', '2:31 AM - 2:45 AM', '2:46 AM - 3:00 AM',
    '3:00 AM - 3:15 AM', '3:16 AM - 3:30 AM', '3:31 AM - 3:45 AM', '3:46 AM - 4:00 AM',
    '4:00 AM - 4:15 AM', '4:16 AM - 4:30 AM', '4:31 AM - 4:45 AM', '4:46 AM - 5:00 AM',
    '5:00 AM - 5:15 AM', '5:16 AM - 5:30 AM', '5:31 AM - 5:45 AM', '5:46 AM - 6:00 AM',
    '6:00 AM - 6:15 AM', '6:16 AM - 6:30 AM', '6:31 AM - 6:45 AM', '6:46 AM - 7:00 AM',
    '7:00 AM - 7:15 AM', '7:16 AM - 7:30 AM', '7:31 AM - 7:45 AM', '7:46 AM - 8:00 AM',
    '8:00 AM - 8:15 AM', '8:16 AM - 8:30 AM', '8:31 AM - 8:45 AM', '8:46 AM - 9:00 AM',
    '9:00 AM - 9:15 AM', '9:16 AM - 9:30 AM', '9:31 AM - 9:45 AM', '9:46 AM - 10:00 AM',
    '10:00 AM - 10:15 AM', '10:16 AM - 10:30 AM', '10:31 AM - 10:45 AM', '10:46 AM - 11:00 AM',
    '11:00 AM - 11:15 AM', '11:16 AM - 11:30 AM', '11:31 AM - 11:45 AM', '11:46 AM - 12:00 PM',
    '12:00 PM - 12:15 PM', '12:16 PM - 12:30 PM', '12:31 PM - 12:45 PM', '12:46 PM - 1:00 PM',
    '1:00 PM - 1:15 PM', '1:16 PM - 1:30 PM', '1:31 PM - 1:45 PM', '1:46 PM - 2:00 PM',
    '2:00 PM - 2:15 PM', '2:16 PM - 2:30 PM', '2:31 PM - 2:45 PM', '2:46 PM - 3:00 PM',
    '3:00 PM - 3:15 PM', '3:16 PM - 3:30 PM', '3:31 PM - 3:45 PM', '3:46 PM - 4:00 PM',
    '4:00 PM - 4:15 PM', '4:16 PM - 4:30 PM', '4:31 PM - 4:45 PM', '4:46 PM - 5:00 PM',
    '5:00 PM - 5:15 PM', '5:16 PM - 5:30 PM', '5:31 PM - 5:45 PM', '5:46 PM - 6:00 PM',
    '6:00 PM - 6:15 PM', '6:16 PM - 6:30 PM', '6:31 PM - 6:45 PM', '6:46 PM - 7:00 PM',
    '7:00 PM - 7:15 PM', '7:16 PM - 7:30 PM', '7:31 PM - 7:45 PM', '7:46 PM - 8:00 PM',
    '8:00 PM - 8:15 PM', '8:16 PM - 8:30 PM', '8:31 PM - 8:45 PM', '8:46 PM - 9:00 PM',
    '9:00 PM - 9:15 PM', '9:16 PM - 9:30 PM', '9:31 PM - 9:45 PM', '9:46 PM - 10:00 PM',
    '10:00 PM - 10:15 PM', '10:16 PM - 10:30 PM', '10:31 PM - 10:45 PM', '10:46 PM - 11:00 PM',
    '11:00 PM - 11:15 PM', '11:16 PM - 11:30 PM', '11:31 PM - 11:45 PM', '11:46 PM - 12:00 PM',
];

const times = [
    '12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM',
    '1:00 AM', '1:15 AM', '1:30 AM', '1:45 AM',
    '2:00 AM', '2:15 AM', '2:30 AM', '2:45 AM',
    '3:00 AM', '3:15 AM', '3:30 AM', '3:45 AM',
    '4:00 AM', '4:15 AM', '4:30 AM', '4:45 AM',
    '5:00 AM', '5:15 AM', '5:30 AM', '5:45 AM',
    '6:00 AM', '6:15 AM', '6:30 AM', '6:45 AM',
    '7:00 AM', '7:15 AM', '7:30 AM', '7:45 AM',
    '8:00 AM', '8:15 AM', '8:30 AM', '8:45 AM',
    '9:00 AM', '9:15 AM', '9:30 AM', '9:45 AM',
    '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
    '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
    '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM',
    '1:00 PM', '1:15 PM', '1:30 PM', '1:45 PM',
    '2:00 PM', '2:15 PM', '2:30 PM', '2:45 PM',
    '3:00 PM', '3:15 PM', '3:30 PM', '3:45 PM',
    '4:00 PM', '4:15 PM', '4:30 PM', '4:45 PM',
    '5:00 PM', '5:15 PM', '5:30 PM', '5:45 PM',
    '6:00 PM', '6:15 PM', '6:30 PM', '6:45 PM',
    '7:00 PM', '7:15 PM', '7:30 PM', '7:45 PM',
    '8:00 PM', '8:15 PM', '8:30 PM', '8:45 PM',
    '9:00 PM', '9:15 PM', '9:30 PM', '9:45 PM',
    '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM',
    '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM',
];
const blockColors = [
    { bg: '#D8EAF6', b: '#24A7FE' },
    { bg: '#E7D1FF', b: '#B87DF5' },
    { bg: '#FBD1F2', b: '#F858F1' },
    { bg: '#DFF3E4', b: '#40992A' },
    { bg: '#FFC1AF', b: '#FF4D19' },
]

const timeToMinutes = (time) => {
    if (!time) return 0;
    const [timePart, period] = time.split(' ');
    const [hourStr, minuteStr] = timePart.split(':');
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);

    if (period === 'PM' && hour !== 12) {
        hour += 12;
    } else if (period === 'AM' && hour === 12) {
        hour = 0;
    }

    return hour * 60 + minute;
};

const calculateSlotIndex = (time) => {
    return timeToMinutes(time) / 15;
};

const calculateSlotSpan = (start, end) => {
    return (timeToMinutes(end) - timeToMinutes(start)) / 15;
};


function DraggableAppointment({ appointment, setOpenInfo, setSelectedCell }) {
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.APPOINTMENT,
        item: { id: appointment.id },
        collect: (monitor) => (
            {
                isDragging: !!monitor.isDragging(),
            }
        ),
    });
    const width = 100 * calculateSlotSpan(appointment.start, appointment.end);

    return (
        <>
            <div
                ref={drag}
                className="appointment-block"
                style={{
                    opacity: isDragging ? 0.5 : 1,
                    gridRowStart: calculateSlotIndex(appointment.start) + 1,
                    gridRowEnd: `span ${calculateSlotSpan(appointment.start, appointment.end)}`,
                    width: `${width - 20}px`,
                    zIndex: isDragging ? 0 : 1,
                    background: appointment.bg,
                    border: `1px solid ${appointment.b}`
                }}
            >
                <div className='appointment-block-info'>
                    <h2 className='appointment-block-h2'>{appointment.patientName}</h2>
                    <p className='appointment-block-p'>{appointment.start} - {appointment.end}</p>
                </div>
                {/* <InfoIcon color={'#383838'} onClick={(e) => handleInfo(e)} /> */}
            </div>
        </>
    );
}

function DroppableTimeSlot({ time, person, onDrop, isOccupied, canDropHere, children, handleClick, tooltip }) {
    const [{ canDrop }, drop] = useDrop({
        accept: ItemTypes.APPOINTMENT,
        drop: (item) => onDrop(item.id, person, time),
        canDrop: () => canDropHere, // Allow drop only if there's enough space and the slot is not occupied
        collect: (monitor) => ({
            canDrop: !!monitor.canDrop(),
        }),
    });

    return (
        <div
            ref={drop}
            className={`time-slot  ${children.length ? '' : 'tooltip-class'} ${isOccupied ? 'occupied-slot' : ''} ${canDrop ? 'can-drop' : ''}`}
            onClick={() => handleClick(time, person, children[0])}
            // title={children.length ? '' : tooltip}
            data-title={children.length ? '' : tooltip}
        >
            {children}
        </div>
    );
}

function SchedulerGrid() {
    const [appointments, setAppointments] = useState([
        { id: 1, person: 'Doctor 1', start: '9:00 AM', end: '10:30 AM', patientName: 'John Doe', patientNumber: 1234567890, date: '2024-09-04', bg: '#D8EAF6', b: '#24A7FE' },
        { id: 2, person: 'Doctor 3', start: '11:00 AM', end: '12:00 PM', patientName: 'Jane Doe', patientNumber: 1234567890, date: '2024-09-04', bg: '#E7D1FF', b: '#B87DF5' },
        { id: 3, person: 'Doctor 1', start: '12:00 AM', end: '1:30 AM', patientName: 'John Doe', patientNumber: 1234567890, date: '2024-09-04', bg: '#FBD1F2', b: '#F858F1' },
        { id: 4, person: 'Doctor 3', start: '1:00 AM', end: '2:00 AM', patientName: 'Jane Doe', patientNumber: 1234567890, date: '2024-09-04', bg: '#DFF3E4', b: '#40992A' },
        { id: 5, person: 'Doctor 1', start: '1:30 AM', end: '2:30 AM', patientName: 'John Doe', patientNumber: 1234567890, date: '2024-09-04', bg: '#FBD1F2', b: '#F858F1' },
    ]);
    const [doctorList, setDoctorList] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [currentTimeCell, setCurrentTimeCell] = useState('');
    const [daysheetDate, setDaysheetDate] = useState('');

    useEffect(() => {
        // let currentTime = new Date().toLocaleTimeString()
        // let dayNight = currentTime.split(" ")
        // let count = dayNight[0].split(':')
        // let slot = calculateSlotSpan('9:00 AM', count[0] + ":00 " + dayNight[1])
        let slot = calculateSlotSpan('12:00 AM', '9:00 AM')
        let scrollWidth = (100 * slot);
        let element = document.getElementById('demo');
        element.scroll({ left: scrollWidth, behavior: 'smooth' });
    }, [])

    const handleTodayDate = () => {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        let today = [year, month, day].join('-');
        setDaysheetDate(today);
    }

    useEffect(() => {
        if (daysheetDate) {
            handleFetchDaysheetData(daysheetDate);
        }
    }, [daysheetDate])

    useEffect(() => {
        handleTodayDate();
        handleFetchDoctorList();
        handleFetchPatientList();
    }, [])

    const handleFetchDaysheetData = async () => {
        try {
            const response = await api.get(`/appointment/daysheet?appointmentDate=${daysheetDate}`);
            if (response.data.status) {
                let appointment = [];
                response.data.response.doctors.map((item) => {
                    if (item.appointmentList) {
                        item.appointmentList.map((app) => {
                            const random = Math.floor(Math.random() * blockColors.length);
                            let color = blockColors[random];
                            app.id = app.appointmentId
                            app.person = app.doctorName
                            app.start = app.fromTime
                            app.end = app.toTime
                            app.date = app.appointmentDate
                            app.bg = color.bg
                            app.b = color.b
                        })
                    }
                    appointment.push(...item.appointmentList)
                })
                setAppointments(appointment);
            }
        } catch (error) {
        } finally {
        }
    }

    const handleFetchPatientList = async () => {
        try {
            const response = await api.get('/patient/getall');
            if (response.data.status) {
                response.data.response.map((item, i) => {
                    item.value = item.patientId
                    item.label = item.patientName
                })
                setPatientList(response.data.response);
            }
        } catch (error) {
        } finally {
        }
    }

    const handleFetchDoctorList = async () => {
        try {
            const response = await api.get('/user/doctor/selectlist');
            if (response.data.status) {
                response.data.response.map((item, i) => {
                    item.doctorName = item.name
                    item.doctorId = item.userfrontid
                })
                setDoctorList(response.data.response)
            }
        } catch (error) {
        } finally {
        }
    }

    const calculateNewEnd = (start, id) => {
        const appt = appointments.find((a) => a.id === id);
        const duration = timeToMinutes(appt.end) - timeToMinutes(appt.start);
        const newStartMinutes = timeToMinutes(start);
        const newEndMinutes = newStartMinutes + duration;

        const hours = Math.floor(newEndMinutes / 60);
        const minutes = newEndMinutes % 60;

        const endPeriod = hours >= 12 ? 'PM' : 'AM';
        const formattedEndHour = hours % 12 === 0 ? 12 : hours % 12;
        const formattedEndMinute = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedEndHour}:${formattedEndMinute} ${endPeriod}`;
    };

    const [rescheduledModel, setRescheduledModel] = useState(false);
    const [movedAppointments, setMovedAppointments] = useState(null);

    const checkSpaceAvailability = (newPerson, newStart, span) => {
        // handleCheckDocAavailablity({date: daysheetDate,fromTime })
        // const startIndex = calculateSlotIndex(newStart);

        // for (let i = startIndex; i < startIndex + span; i++) {
        //     const time = times[i];
        //     if (
        //         appointments.some(
        //             (appt) =>
        //                 appt.person === newPerson &&
        //                 timeToMinutes(time) >= timeToMinutes(appt.start) &&
        //                 timeToMinutes(time) < timeToMinutes(appt.end)
        //         )
        //     ) {
        //         return false; // Slot is occupied, not enough space
        //     }
        // }
        // return true; // Enough space is available
        return true;
    };

    const moveAppointment = async (id, newPerson, newStart) => {
        setMovedAppointments({ id: id, newPerson: newPerson, newStart: newStart })
        setRescheduledModel(true);
        // const newEnd = calculateNewEnd(newStart, id);
        // let changeApp = appointments.filter((item) => item.id === id);
        // let appointmentData = {
        //     "appointmentId": id,
        //     "appointmentDate": changeApp[0].date,
        //     "fromTime": newStart,
        //     "toTime": newEnd,
        //     "patientId": changeApp[0].patientId,
        //     "patientName": changeApp[0].patientName,
        //     "doctorId": changeApp[0].doctorId,
        //     "doctorName": changeApp[0].doctorName,
        //     "remarks": changeApp[0].remarks,
        //     "appointmentStatus": "Confirmed"
        // }

        // try {
        //     await api.post(`/appointment/save`, appointmentData);
        //     toast.success('Data updated successfully.');
        // } catch (error) {
        //     toast.error('Error updating data.');
        // }

        // handleFetchDaysheetData();
    };

    const handleRescheduledAppointments = async () => {
        setRescheduledModel(false);
        const newEnd = calculateNewEnd(movedAppointments.newStart, movedAppointments.id);
        let changeApp = appointments.filter((item) => item.id === movedAppointments.id);
        let appointmentData = {
            "appointmentId": movedAppointments.id,
            "appointmentDate": changeApp[0].date,
            "fromTime": movedAppointments.newStart,
            "toTime": newEnd,
            "patientId": changeApp[0].patientId,
            "patientName": changeApp[0].patientName,
            "doctorId": changeApp[0].doctorId,
            "doctorName": changeApp[0].doctorName,
            "remarks": changeApp[0].remarks,
            "appointmentStatus": changeApp[0].appointmentStatus
        }

        try {
            await api.post(`/appointment/save`, appointmentData);
            toast.success('Data updated successfully.');
        } catch (error) {
            toast.error('Error updating data.');
        }

        handleFetchDaysheetData();
    }

    const [openModel, setOpenModel] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);

    const handleClick = (time, person, children) => {
        if (children && children !== false) {
            let appointmentData = children?.props?.appointment
            // setOpenModel(true);
            setIsEdit(true);
            setSelectedCell(appointmentData)
            setOpenInfo(true)
        }
        else {
            setOpenModel(true);
            setIsEdit(false);
            if (person) {
                setSelectedCell({
                    start: time,
                    person: person,
                    doctorId: doctorList.filter((item) => item.doctorName === person)
                })
            } else {
                setSelectedCell({
                    start: time,
                    person: person,
                })
            }

        }
    }

    const handleClose = () => {
        setOpenModel(false);
        setIsEdit(false);
        setSelectedCell(null);
        setOpenInfo(false);
    }

    const handleCheckDocAavailablity = async (data) => {
        let param = {
            "appointmentId": data.appointmentId,
            "appointmentDate": data.date,
            "fromTime": data.start,
            "toTime": data.end,
            "doctorId": data.doctorId
        }
        try {
            const result = await api.post(`/appointment/checkdocavailablity`, param);
            if (result.data.status) {
                return result.data.response.isAvailable
            }
        } catch (error) {
            toast.error('Error updating data.');
        }
    }

    const handleAddAppointment = async (data) => {
        let appointmentData = {
            "appointmentId": data.id ? data.id : 0,
            "appointmentDate": data.date,
            "fromTime": data.start,
            "toTime": data.end,
            "patientId": data.patientId,
            "patientName": data.patientName,
            "doctorId": data.doctorId,
            "doctorName": data.doctorName,
            "remarks": data.remarks,
            "appointmentStatus": data.appointmentStatus
        }
        try {
            await api.post(`/appointment/save`, appointmentData);
            toast.success('Data updated successfully.');
        } catch (error) {
            toast.error('Error updating data.');
        }
        handleFetchDaysheetData();
        setOpenModel(false);
        setIsEdit(false);
        setSelectedCell(null);
        setOpenInfo(false);
    }

    const [openInfo, setOpenInfo] = useState(false);

    const handleDelete = async () => {
        try {
            const response = await api.get(`/appointment/${selectedCell.appointmentId}/delete`);
            if (response.data.status) {
                handleClose();
                handleFetchDaysheetData();
                toast.success('Data deleted successfully.');
            }
        } catch (error) {
        } finally {
        }
    }

    const handleUpdateStatus = async (status) => {
        try {
            const data = {};
            const response = await api.post(`/appointment/${selectedCell.appointmentId}/status/update?status=${status}`,data);
            if (response.data.status) {
                handleClose();
                handleFetchDaysheetData();
                // toast.success('Data deleted successfully.');
            }
        } catch (error) {
        } finally {
        }
    }

    return (
        <Box display="flex"
            flexDirection="column"
            w={"100%"}
            h={"100vh"}
            bg={"#F8FAFF"}
        >
            <Box as="header">
                <Topbar title="Daysheet" />
            </Box>
            <Box
                m={"0 16px 16px"}
                pt={"16px"}
                borderRadius="8px 8px 0px 0px"
                boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
                bg={"white"}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: "2px solid #f6f6f6",
                        padding: "12px 10px",
                        marginBottom: "12px",
                    }}
                >
                    <div>
                        <Input placeholder='Select Date and Time' id='session-date' size='md' type='date' value={daysheetDate} onChange={(e) => setDaysheetDate(e.target.value)} />
                    </div>
                    <div>
                        <span className="add-sq-container">
                            <PlusSquareIcon onClick={() => setOpenModel(true)} />
                        </span>
                    </div>
                </div>
                <DndProvider backend={HTML5Backend}>
                    <div className="scheduler-container">
                        <div className="scheduler-grid" id='demo'>
                            <div className="grid-header grid-colums-header">
                                <div className="people-column sticky-column" style={{ marginTop: '0px', height: '50px', borderBottom: '1px solid #ccc' }}>Doctors</div>
                                <div className="time-slots-container">
                                    {showTimes.map((time) => (
                                        <div key={time} className="time-slot-header">
                                            {time}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='grid-header grid-colums'>
                                <div className="people-column sticky-column" style={{ marginTop: '0px', height: '50px', borderBottom: '1px solid #ccc' }}>Slots</div>
                                <div className="time-slots-container">
                                    {slots.map((time) => (
                                        <div key={time} className="time-slot-header" style={{ fontSize: '10px' }}>
                                            {time}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* <div className='grid-main-container'> */}
                            {doctorList.map((person) => (
                                <div key={person.doctorName} className="grid-row grid-colums" style={{ position: 'relative' }}>
                                    <div className="people-column sticky-column">{person.doctorName}</div>
                                    <div className="time-slots-container">
                                        {times.map((time, i) => {
                                            const isOccupied = appointments.some(
                                                (appt) =>
                                                    appt.person?.doctorName === person &&
                                                    timeToMinutes(time) >= timeToMinutes(appt.start) &&
                                                    timeToMinutes(time) < timeToMinutes(appt.end)
                                            );

                                            const canDropHere = checkSpaceAvailability(
                                                person,
                                                time,
                                                calculateSlotSpan(
                                                    appointments.find((appt) => appt.id === 1)?.start || time,
                                                    appointments.find((appt) => appt.id === 1)?.end || time
                                                )
                                            );

                                            return (
                                                <DroppableTimeSlot
                                                    key={i}
                                                    time={time}
                                                    person={person.doctorName}
                                                    isOccupied={isOccupied}
                                                    canDropHere={canDropHere && !isOccupied}
                                                    onDrop={(id) => moveAppointment(id, person, time)}
                                                    appointments={appointments}
                                                    handleClick={(time, person, children) => handleClick(time, person, children)}
                                                    tooltip={slotsTooltip[i]}
                                                >
                                                    {appointments
                                                        .filter(
                                                            (appt) =>
                                                                appt.person === person.doctorName &&
                                                                timeToMinutes(time) == timeToMinutes(appt.start)
                                                        )
                                                        .map((appt) => (
                                                            <DraggableAppointment key={appt.id} appointment={appt} setOpenInfo={setOpenInfo} setSelectedCell={setSelectedCell} />
                                                        ))}
                                                </DroppableTimeSlot>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                            {/* </div> */}
                        </div>
                        {openModel && (
                            <AddAppointmentModal
                                isOpen={openModel}
                                onClose={() => setOpenModel(false)}
                                isEdit={isEdit}
                                selectedCell={selectedCell}
                                times={times}
                                handleAddAppointment={handleAddAppointment}
                                patientList={patientList}
                                doctorList={doctorList}
                                daysheetDate={daysheetDate}
                                handleCheckDocAavailablity={handleCheckDocAavailablity}
                            />
                        )}
                        {openInfo && (
                            <AppointmentInfoModel
                                isOpen={openInfo}
                                onClose={handleClose}
                                selectedCell={selectedCell}
                                handleDelete={handleDelete}
                                setOpenModel={setOpenModel}
                                setIsEdit={setIsEdit}
                                handleUpdateStatus={handleUpdateStatus}
                            />
                        )}
                        {rescheduledModel && (
                            <RescheduledModel
                                isOpen={rescheduledModel}
                                onClose={() => setRescheduledModel(false)}
                                handleRescheduledAppointments={handleRescheduledAppointments}
                            />
                        )}
                    </div>
                </DndProvider>
            </Box>
        </Box >
    );
}

export default SchedulerGrid;