import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "./routers/routes";
import { persistor, store } from "./store/index";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <ChakraProvider theme={theme}>
          <GlobalStyles />
          <AppRoutes />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            style={{ zIndex: 1000 }}
            theme="dark"
          />
        </ChakraProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);

reportWebVitals();
