import React from "react";

import { Bar, Line, Pie, PolarArea } from "react-chartjs-2";
import "chart.js/auto";
import { Box, IconButton } from "@chakra-ui/react";
import Topbar from "./Topbar";
import TableIcon from "../../components/icons/TableIcon";
import PeopleIcon from "../../components/icons/PeopleIcon";
import UsersIcon from "../../components/icons/UsersIcon";

function DashboardHome() {
  // Data for the summary cards
  const totalPatients = 1200;
  const totalAppointments = 450;
  const totalDoctors = 25;

  // Data for the charts
  const datewiseAppointmentsData = {
    labels: ["01 Aug", "02 Aug", "03 Aug", "04 Aug", "05 Aug"],
    datasets: [
      {
        label: "Total Appointments",
        data: [30, 45, 25, 60, 40],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const doctorPatientRatioData = {
    labels: ["Doctors", "Patients"],
    datasets: [
      {
        label: "Doctor to Patient Ratio",
        data: [25, 1200],
        backgroundColor: ["rgba(255, 99, 132, 0.6)", "rgba(54, 162, 235, 0.6)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const appointmentTrendData = {
    labels: ["01 Aug", "02 Aug", "03 Aug", "04 Aug", "05 Aug"],
    datasets: [
      {
        label: "Appointment Trend",
        data: [20, 35, 40, 30, 45],
        fill: false,
        backgroundColor: "rgba(153, 102, 255, 0.6)",
        borderColor: "rgba(153, 102, 255, 1)",
      },
    ],
  };

  const patientDistributionData = {
    labels: ["New Patients", "Returning Patients"],
    datasets: [
      {
        label: "Patient Distribution",
        data: [200, 1000],
        backgroundColor: ["rgba(255, 206, 86, 0.6)", "rgba(75, 192, 192, 0.6)"],
        borderColor: ["rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <Box display="flex" flexDirection="column" w={"100%"} h={"100vh"} bg={"#F8FAFF"}>
      <Box as="header">
        <Topbar title="Dashboard" />
      </Box>

      {/* Title Searchbar and Add Patient Button */}
      <Box m={"0 16px 16px"} pt={"16px"} borderRadius="8px 8px 0px 0px" boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"} bg={"white"}>
      <div>
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              border:"2px solid #EFEFEF",
              flex: 1,
              marginRight: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h3>Total Patients</h3>
              <p style={{fontWeight:800,fontSize:"2rem"}}>{totalPatients}</p>
            </div>
            <div style={{margin:"auto 0"}}>
              <IconButton size='lg'  icon={<PeopleIcon />}/>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              border:"2px solid #EFEFEF",
              flex: 1,
              marginRight: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h3>Total Appointments</h3>
              <p style={{fontWeight:800,fontSize:"2rem"}}>{totalAppointments}</p>
            </div>
            <div style={{margin:"auto 0"}}>
              <IconButton size='lg'  icon={<TableIcon />}/>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              border:"2px solid #EFEFEF",
              flex: 1,
              marginRight: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h3>Total Doctors</h3>
              <p style={{fontWeight:800,fontSize:"2rem"}}>{totalDoctors}</p>
            </div>
            <div style={{margin:"auto 0"}}>
              <IconButton size='lg'  icon={<UsersIcon />}/>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          <div
            style={{
              // flex: "1 1 20%",
              width: "45%",
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <h3>Date-wise Total Appointments</h3>
            <Bar data={datewiseAppointmentsData} options={chartOptions} />
          </div>

          <div
            style={{
              // flex: "1 1 20%",
              width: "25%",
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <h3>Doctor to Patient Ratio</h3>
            <Pie data={doctorPatientRatioData} />
          </div>
          <div
            style={{
              // flex: "1 1 20%",
              width: "25%",
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <h3>Patient Distribution</h3>
            <Pie data={patientDistributionData} />
          </div>

          <div
            style={{
              // flex: "1 1 20%",
              width: "35%",
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <h3>Appointment Trend</h3>
            <PolarArea data={appointmentTrendData} options={chartOptions} />
          </div>
          <div
            style={{
              // flex: "1 1 20%",
              width: "60%",
              textAlign: "end",
              backgroundColor: "#ffffff",
              padding: "20px",
              borderRadius: "10px",
              marginLeft: "auto",
            }}
          >
            <h3>Appointment Trend</h3>
            <Line data={appointmentTrendData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
      </Box>
    </Box>
  );
}

export default DashboardHome;
