import SchedulerGrid from './SchedulerGrid';
import './SchedulerGrid.css';
import './index.css'

function CalendarComponent6() {

    return (
        <SchedulerGrid />
    );
}

export default CalendarComponent6;
