import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  border,
  Flex,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

const CustomDatePicker = ({ selectedDate, onDateChange, maxDate = null, minDate = null ,age,isDOB}) => {


  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div className="date-picker-custom-content">
        <label onClick={props.onClick} ref={ref}>         
          {((isDOB) ? (`${props.value} ( ${props.age} years old )`) : (props.value)) || props.placeholder}
        </label>
        <CalendarIcon onClick={props.onClick} style={{margin:'auto'}} />
      </div>
    );
  });


  return (
    <Box className="custom-date-picker-box" style={{
      border:'1.4px solid #D2D2D2',
      width:'100%',
      backgroundColor: "#F8FAFF",
      padding:'2px',
      borderRadius:'5px'
  }}>
    <DatePicker 
            selected={selectedDate}
            onChange={onDateChange}
            maxDate={maxDate}
            minDate={minDate}
            showMonthDropdown
            dateFormat="dd-MM-yyyy"
            showYearDropdown
            dropdownMode="select"
            showIcon
            toggleCalendarOnIconClick
            className="date-picker-custom-content"
            style={{backgroundColor:'none',width:'100% !important'}}
            icon={<CalendarIcon style={{margin:'auto',position:'relative',left:((isDOB) ? ('100%') : ('92%'))}} />}
            // customInput={<CustomInput age={age}/>}
          />
      <Flex>
        

      </Flex>
      {/* <Flex>
      <IconButton
            aria-label="Open Date Picker"
            icon={<CalendarIcon />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              // const input = e.target.closest(".react-datepicker-wrapper")
              //   .querySelector("input");
              // input.focus();
            }}
          />
      </Flex> */}
    </Box>
    // <InputGroup style={{border:'1px solid #D2D2D2',borderRadius:'6px'}} zIndex={10000}>
      
    //   <InputRightElement>
        
    //   </InputRightElement>
    // </InputGroup>
  );
};

export default CustomDatePicker