import { useState } from "react";
import { validateEmail } from "../utils/helper";
import api from "../api";

// Initial state for form fields
const initialFormState = {
  patientId:0,
  patientFirstname: "",
  referenceBy: "",
  patientLastname: "",
  patientEmail: "",
  patientDob: new Date(),
  patientGender: "",
  contactDetails: [{ patientContactIndex: 0,patientContactId: 0, patientContactMobileNo: "", patientContactBelongsTo: "", patientContactOther: "" }],
  patientAddressLine1: "",
  patientAddressLine2: "",
  patientPincode: "",
  patientCityCode: "",
  appointmentDate: new Date(),
  deleteContacts: ""
};

// Initial state for form errors
const initialErrorState = {
  patientFirstname: "",
  referenceBy: "",
  patientLastname: "",
  patientEmail: "",
  patientDob: "",
  patientGender: "",
  contactDetails: [{ patientContactMobileNo: "", patientContactBelongsTo: "",patientContactOther: ""}],
  patientAddressLine1: "",
  patientPincode: "",
  patientCityCode: "",
  appointmentDate: "",
};

const defaultContactDetails = {
  "patientContactIndex": 1,
  "patientContactId": 0,
  "patientContactMobileNo": "",
  "patientContactBelongsTo": "",
  "patientContactOther": ""
}

const usePatientForm = () => {
  const [formData, setFormData] = useState(initialFormState);
  const [formErrors, setFormErrors] = useState(initialErrorState);
  const [deletedContacts, setDeletedContacts] = useState('');


  // Validation function (simple example)
  const validate = () => {
    let errors = { ...initialErrorState };

    if (!formData.patientFirstname) errors.patientFirstname = "Name is required";
    // if (!formData.referenceBy) errors.referenceBy = "Doctor name is required";
    // if (!formData.patientLastname) errors.patientLastname = "Last name is required";
    // if (!formData.patientGender) errors.patientGender = "Please select Gender";

    // if (formData.patientEmail && !validateEmail(formData.patientEmail)) errors.patientEmail = "Please enter valid Email";
    // if (!formData.patientEmail) errors.patientEmail = "Email is required";
    // if (!formData.patientDob) errors.patientDob = "Birth Date is required";

    // if (!formData.patientAddressLine1) errors.patientAddressLine1 = "Address is required";
    // if (!formData.patientCityCode) errors.patientCityCode = "Please enter your city";
    // if (!formData.patientPincode) errors.patientPincode = "Pin Code is required";

    // if (!formData.appointmentDate) errors.appointmentDate = "Appointment Date is required";

    let validated = Object.keys(errors).every((key) => {
      if (key === "contactDetails") {
        return true
      }
      return !errors[key]
    });
    let contactValidated = [];
    let error = formData.contactDetails.map((s) => {
      // if (s.patientContactMobileNo && s.patientContactBelongsTo) {

      //   if((s.patientContactBelongsTo == 'other') && (s.patientContactOther && s.patientContactOther != null)){
  
      //     contactValidated.push(true)
      //   }
      //   // else{
      //   //   debugger
      //   //   contactValidated.push(false)
      //   // }
        
      // } else {
      //   contactValidated.push(false)
      // }
      // return {
      //   patientContactMobileNo: s.patientContactMobileNo ? "" : "Mobile number is required",
      //   patientContactBelongsTo: s.patientContactBelongsTo ? "" : "Please select one option",
      //   patientContactOther: (s.patientContactBelongsTo && s.patientContactBelongsTo != 'other') ? "" : "Please enter belongs to",
      // }
    });
    errors.contactDetails = error;
    let contactIsValidated = contactValidated.every(s => s === true)
    setFormErrors(errors);
    return validated && contactIsValidated;
  };

  const addContact = () => {
    setFormData(prev => {
      return {
        ...prev,
        contactDetails: [
          ...prev.contactDetails,
          { patientContactIndex: (prev.contactDetails.length + 1),patientContactId: 0, patientContactMobileNo: "", patientContactBelongsTo: "", patientContactOther: "" }
        ]
      }
    })
  }

  const getDeletedContacts = (deletedPatientContactId) => {
    console.log("DELETED CONTACTS PREV:", deletedContacts);
  
    // Ensure deletedContacts is a string
    let deletedContactList = deletedContacts ? deletedContacts.split(",").map(item => item.trim()) : [];
  
    // Check if the ID is already in the list
    if ((deletedPatientContactId != 0) && (!deletedContactList.includes(deletedPatientContactId.toString()))) {
      deletedContactList.push(deletedPatientContactId.toString());
    }
  
    const updatedDeletedContacts = deletedContactList.join(",");
    setDeletedContacts(updatedDeletedContacts);
  
    console.log("UPDATED DELETED CONTACTS:", updatedDeletedContacts);
    return updatedDeletedContacts;
  };

  const removeContact = (patientContactId,patientContactIndex) => {
    setFormData(prev => {
      return {
        ...prev,
        contactDetails: prev.contactDetails.filter(s => s.patientContactIndex !== patientContactIndex),
        'deleteContacts': getDeletedContacts(patientContactId)
      }
    })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setFormErrors(prev => ({ ...prev, [name]: value ? "" : prev[name] }))
  };

  const handleContactsChange = (e, patientContactIndex) => {
    const { name, value } = e.target;
    const updatedContacts = formData.contactDetails.map((contact, i) => {
      return patientContactIndex === contact.patientContactIndex ? { ...contact, [name]: value } : contact
    });

    let errors = updatedContacts.map((s) => {
      return {
        patientContactMobileNo: s.patientContactMobileNo ? "" : "Mobile number is required",
        patientContactBelongsTo: s.patientContactBelongsTo ? "" : "Please select one option",
        patientContactOther: checkpatientContactOther(s),
      }
    });

    setFormData(prev => ({ ...prev, contactDetails: updatedContacts }));
    setFormErrors(prev => ({ ...prev, contactDetails: errors }))
  };

  const checkpatientContactOther = (contact) => {
    if(contact.patientContactBelongsTo && contact.patientContactBelongsTo != 'other'){
      return "";
    }else{
      if(contact.patientContactOther){
        return "";
      }else{
        return "Please enter belongs to";
      }
    }
  }

  const transformPatientData = (data) => {
    console.log("DATA :::" , data);
  
    return {
      patientId: data.patientId || 0,
      patientFirstname: data.patientFirstname || "",
      referenceBy: data.referenceBy || "",
      patientLastname: data.patientLastname || "",
      patientEmail: data.patientEmail || "",
      patientDob: data.patientDob ? new Date(data.patientDob) : new Date(),
      patientGender: data.patientGender || "",
      contactDetails: (data.patientContactDetailsVos && data.patientContactDetailsVos.length > 0)
        ? data.patientContactDetailsVos.map((contact, index) => ({
            patientContactIndex: (index + 1),
            patientContactId: contact.patientContactId || 0,
            patientContactMobileNo: contact.patientContactMobileNo || "",
            patientContactBelongsTo: contact.patientContactBelongsTo || "",
            patientContactOther: contact.patientContactOther || ""
          }))
        : [{
            patientContactIndex: 1,
            patientContactId: 0,
            patientContactMobileNo: "",
            patientContactBelongsTo: "",
            patientContactOther: ""
          }],
      patientAddressLine1: data.patientAddressLine1 || "",
      patientAddressLine2: data.patientAddressLine2 || "",
      patientPincode: data.patientPincode || "",
      patientCityCode: data.patientCityCode || "",
      appointmentDate: data.appointmentDate ? new Date(data.appointmentDate) : new Date(),
      deleteContacts: "" // Assuming this is handled elsewhere or remains an empty string
    };
  };
  

  const fetchDataPatientInfo = async (id) => {
    try {
      const response = await api.get(`/patient/${id}`);
      if (response.data.status) {
        console.log("response.data.response :::: " ,response.data.response)
        const transformedData = transformPatientData(response.data.response);
        console.log("transformedData :::: " , transformedData)
        if(transformedData)
        setFormData(transformedData);
        // setPatient(response.data.response);
      }
    } catch (error) {
      // setError(error);
      console.error(error);
    } finally {
      // setLoading(false);
      console.log("HERE INSIDE THE FINALLY PAGE")
    }
  };

  return {
    formData,
    formErrors,
    handleInputChange,
    handleContactsChange,
    validate,
    addContact,
    removeContact,
    fetchDataPatientInfo
  };
};

export default usePatientForm;
