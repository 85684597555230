import { Box, Button, Flex, FocusLock, HStack, Image, Input, InputGroup, InputLeftElement, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverTrigger, Portal, Text, useDisclosure } from '@chakra-ui/react'
import BackArrowIcon from '../../components/icons/BackArrowIcon'
import SearchIcon from '../../components/icons/SearchIcon'
import NotificationIcon from '../../components/icons/NotificationIcon'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/auth/authSlice'
import './topbar.css'
import { useEffect, useState } from 'react'

function Topbar({ title }) {
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [userInfo, setUserInfo] = useState({})
  const dispatch = useDispatch();
  const handleLogOut = () => {
    dispatch(logout());
    navigate('/login')
  }

  useEffect(() => {
    let loginUser = localStorage.getItem('userInfo');
    setUserInfo(JSON.parse(loginUser));
  }, [])

  return (
    <Flex h={"100px"} p={"32px"} alignItems="center">
      <Flex width="100%" alignItems="center" gap={"16px"}>
        <Box cursor={"pointer"} onClick={() => navigate(-1)}>
          <BackArrowIcon />
        </Box>
        <Text as={"h3"} fontSize={32} fontWeight={700}>{title}</Text>
        <InputGroup display='none'>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon />
          </InputLeftElement>
          <Input w={"284px"} placeholder='Search' border={"1px solid grey"} />
        </InputGroup>
      </Flex>

      <Flex alignItems="center" gap={"16px"}>
        <NotificationIcon />
        <Popover
          placement='bottom'
        >
          <PopoverTrigger>
            <Image cursor={"pointer"} src={`${process.env.PUBLIC_URL}/images/UserCircle.png`} alt='User Profile Image' />
          </PopoverTrigger>
          <Portal>
            <PopoverContent padding={'0px 0px 0px 0px'}>
              <FocusLock returnFocus persistentFocus={false}>
                {/* <PopoverArrow /> */}
                <HStack display={"flex"} justifyContent={'center'}>
                  <Image cursor={"pointer"} width={'50%'} src={`${process.env.PUBLIC_URL}/images/UserCircleHover.png`} alt='User Profile Image' />
                </HStack>
                <HStack marginLeft={4} spacing={4} justifyContent={'left'}>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>Name</Text>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>{userInfo.userName}</Text>
                </HStack>
                <HStack marginLeft={4} spacing={4} justifyContent={'left'}>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>Email ID</Text>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>{userInfo.email}</Text>
                </HStack>
                <HStack marginLeft={4} spacing={4} justifyContent={'left'}>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>Mobile No.</Text>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>+91 987654321</Text>
                </HStack>
                <HStack marginLeft={4} spacing={4} justifyContent={'left'}>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>Role</Text>
                  <Text as={"h4"} fontSize={16} fontWeight={700}>{userInfo.role}</Text>
                </HStack>
                <HStack marginLeft={4} spacing={1} marginTop={"24px"} display={'flex'} justifyContent={'center'}>
                  <Button
                    alignSelf={"end"}
                    w={"150px"}
                    h={"36px"}
                    mr={"10px"}
                    background={"white"}
                    border={"1px #4C8BF5 solid"}
                    color={"#4C8BF5"}
                    _hover={{
                      opacity: 0.9,
                    }}
                    style={{ verticalAlign: "middle" }}
                    fontSize={"14px"}
                    fontWeight={700}
                    fontFamily={"'Open Sans'"}
                  >
                    Change password
                  </Button>
                  <Button
                    alignSelf={"end"}
                    w={"150px"}
                    h={"36px"}
                    mr={"10px"}
                    background={"white"}
                    border={"1px #4C8BF5 solid"}
                    color={"#4C8BF5"}
                    _hover={{
                      opacity: 0.9,
                    }}
                    style={{ verticalAlign: "middle" }}
                    fontSize={"14px"}
                    fontWeight={700}
                    fontFamily={"'Open Sans'"}
                    onClick={() => handleLogOut()}
                  >
                    Logout
                  </Button>
                </HStack>
                <PopoverFooter
                  padding={0} marginTop={2}
                  display={'flex'} justifyContent={'center'}
                  backgroundColor={'rgba(76,139,245,0.27)'}>
                  <Text as={"h4"} fontSize={14} fontWeight={700}>Last Login - 23:00 23 Aug 2024</Text>
                </PopoverFooter>
              </FocusLock>
            </PopoverContent>
          </Portal>
        </Popover>
      </Flex>
    </Flex>
  )
}

export default Topbar