import { Icon } from '@chakra-ui/react'

function ChatCentered({ color = "#383838" }) {
    return (
        <Icon fontSize={24} color={color}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V17.25C2.25 17.6478 2.40804 18.0294 2.68934 18.3107C2.97064 18.592 3.35218 18.75 3.75 18.75H9.41531L10.6978 20.9944C10.829 21.2239 11.0186 21.4146 11.2473 21.5473C11.4759 21.68 11.7356 21.7499 12 21.7499C12.2644 21.7499 12.5241 21.68 12.7527 21.5473C12.9814 21.4146 13.171 21.2239 13.3022 20.9944L14.5847 18.75H20.25C20.6478 18.75 21.0294 18.592 21.3107 18.3107C21.592 18.0294 21.75 17.6478 21.75 17.25V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM20.25 17.25H14.1497C14.0169 17.2488 13.8862 17.2829 13.7709 17.3488C13.6556 17.4147 13.5598 17.51 13.4934 17.625L11.9934 20.25L10.4934 17.625C10.427 17.51 10.3313 17.4147 10.216 17.3488C10.1007 17.2829 9.96998 17.2488 9.83719 17.25H3.75V5.25H20.25V17.25Z" fill="#444444" />
            </svg>
        </Icon>
    )
}

export default ChatCentered