import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "../features/auth/Login";
import ForgotPassword from "../features/auth/ForgotPassword";
import DashboardHome from "../features/dashboard/DashboardHome";
import { useSelector } from "react-redux";
import Patients from "../features/dashboard/patients/Patients";
import AddPatient from "../features/dashboard/patients/AddPatient";
import PatientCase from "../features/dashboard/patients/PatientCase";
import PatientSummaryInfo from "../features/dashboard/patients/PatientSummaryInfo";
import CalendarComponent from "../components/Daysheet2/CalendarComp";
import CalendarComponent3 from "../components/Daysheet3";
import TransactionList from "../features/dashboard/patients/TransactionList";
import TransactionView from "../features/dashboard/patients/TransactionView";
import CalendarComponent4 from "../components/DaySheet4";
import CalendarComponent5 from "../components/DaySheet5";
import UsersList from "../features/users/UsersList";
import CalendarComponent6 from "../components/DaySheet6";

// import Login from "@features/Auth/Login/Login";
// import Registration from "@features/Auth/Registration/Registration";
// import Dashboard from "../pages/Dashboard";
// import ScheduleAppoinment from "../pages/ScheduleAppoinment";
// import NotFound from "../pages/NotFound";
// import newScheduleAppoinment from "../features/ScheduleAppoinment/newScheduleAppoinment";
// import ForgotPassword from "@features/Auth/ForgotPassword/ForgotPassword";
// import NewScheduleAppoinment from "../features/ScheduleAppoinment/newScheduleAppoinment";

const AppRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
  const allowedRoles = ["admin", "staff", "doctor"]
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          
          element={
            isAuthenticated  ? (
              <Navigate to={"/dashboard"} />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/forgot-password"
          element={
            isAuthenticated ? (
              <Navigate to={"/dashboard"} />
            ) : (
              <ForgotPassword />
            )
          }
        />
        {/* <Route path="/" element={<Login />} /> */}
        {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
        <Route
          path="/dashboard"
          element={<PrivateRoute element={DashboardHome} roles={allowedRoles} />}
        />
        <Route
          path="/patients"
          element={<PrivateRoute element={Patients} roles={allowedRoles} />}
        />
        <Route
          path="/patients/add-patient"
          element={<PrivateRoute element={AddPatient} roles={allowedRoles} />}
        />
        <Route
          path="/patients/:id/edit"
          element={<PrivateRoute element={AddPatient} roles={allowedRoles} />}
        />
        <Route
          path="/patients/:id/case"
          element={<PrivateRoute element={PatientCase} roles={allowedRoles} />}
        />
        .
        <Route path="/case" element={<PatientCase />} />
        <Route
          path="/patients/:id/summary"
          element={
            <PrivateRoute element={PatientSummaryInfo} roles={allowedRoles} />
          }
        />
        <Route
          path="/settings"
          element={<PrivateRoute element={Patients} roles={allowedRoles} />}
        />
        <Route 
          path="/daysheet"
          element={<PrivateRoute element={CalendarComponent6} roles={allowedRoles} />}/>
        <Route
          path="/users"
          element={<PrivateRoute element={UsersList} roles={allowedRoles} />}
        />
        <Route
          path="/transactions"
          element={<PrivateRoute element={TransactionList} roles={allowedRoles} />}
        />
        <Route
          path="/transaction/:id"
          element={<PrivateRoute element={TransactionView} roles={allowedRoles} />}
        />
        {/* <Route
			path="/registration"
			element={
				<Registration />
				}
				/> */}
        {/* <Route
			path="/dashboard"
			element={<PrivateRoute element={Dashboard} roles={["admin", "user"]} />}
			/> */}
        {/* <Route
			path="/dashboard"
			element={<NewScheduleAppoinment />}
			/> */}
        {/* <Route
			path="/schedule-appointment"
			element={
				<PrivateRoute
				element={ScheduleAppoinment}
				roles={["admin", "user"]}
				/>
				}
				/>
				<Route
				path="/new-schedule-appointment"
				element={
					<PrivateRoute
					element={newScheduleAppoinment}
					roles={["admin", "user"]}
					/>
					}
					/> */}
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to={"/dashboard"} />
            ) : (
              <Login />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
