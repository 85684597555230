import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, InputGroup, InputRightElement, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import EyeIcon from "../../components/icons/EyeIcon";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../../store/auth/authSlice";
import api from "../../api";
import axios from "axios";

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [showPassword, setShowPassword] = useState(false);
	const [errors, setErrors] = useState({});
	const [loader, setLoader] = useState(false)


	const validate = () => {
		const newErrors = {};

		if (!email || !password) {
			if (!email) newErrors.email = "Please enter Email ID";
			if (!password) newErrors.password = "Please enter your Password";
		} else {
			newErrors.email = "";
			newErrors.password = "";
		}
		return newErrors;
	};

	useEffect(() => {
		if (errors.email && email) {
			setErrors(prev => ({ ...prev, email: null }))
		}
		if (errors.password && password) {
			setErrors(prev => ({ ...prev, password: null }))
		}
	}, [errors, email, password])


	const onHandleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validate();
		if (validationErrors.email || validationErrors.password) {
			setErrors(validationErrors);
			return;
		}

		setLoader(true);
		// API call
		try {
			const response = await api.post('/user/login', {
				username: email,
				password
			});

			if (response.data.status) { // response.data.status) { // If response success
				let token = response.data.response.token
				toast.success("Login successfully");
				dispatch(login({
					token: token, // ,//response.token,
					email: response.data.response.email, // ,
					role: response.data.response.roleName === "COMPANY" ? "admin" : response.data.response.roleName?.toLowerCase(), //,
				}));
				localStorage.setItem('access_token', token);
				localStorage.setItem('userInfo', JSON.stringify({
					isAuthenticated: true,
					token: token, // token,//response.token,
					email: response.data.response.email, // response.data.response.email,
					role: response.data.response.roleName === "COMPANY" ? "admin" : response.data.response.roleName?.toLowerCase(), //response.data.response.roleName === "COMPANY" ? "admin" : "staff",
				}));
				navigate('/dashboard');
			} else {
				toast.error("Email ID/Password incorrect!");
			}
		} catch (error) {
			toast.error("Email ID/Password incorrect!");
		}
		setLoader(false);
	};

	return (
		<Flex
			flexDirection="column"
			justifyContent="space-between"
			alignItems="center"
			height={"100vh"}
		>
			<Image
				src={`${process.env.PUBLIC_URL}/images/home-page-left-design.png`}
				alt='home-page-left-design-logo'
				style={{ width: "303px", position: "fixed", zIndex: 1000, top: 0, left: 0 }}
			/>
			<Image
				src={`${process.env.PUBLIC_URL}/images/home-page-right-design.png`}
				alt='home-page-right-design-logo'
				style={{ width: "387px", position: "fixed", zIndex: 1000, bottom: 0, right: 0, }}
			/>
			<Box>
			</Box>

			<Flex
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				width="294px"
			>
				<Box mb={8}>
					<Image
						src={`${process.env.PUBLIC_URL}/images/logo.png`}
						alt='logo'
						style={{ width: '290px', }}
					/>
				</Box>

				<Flex flexDir={"column"} alignSelf={"flex-start"} mb={5}>
					<Text as={"h2"} fontSize={32} fontWeight={700}>
						Welcome Back
					</Text>
					<Text fontSize={14} fontWeight={400} color={"#393939"}>
						Please enter your details
					</Text>
				</Flex>

				<form style={{ width: "100%" }} onSubmit={onHandleSubmit}>
					<Stack
						width="100%"
						spacing={3}
						direction='column'
						mb={4}
					>
						<FormControl isInvalid={errors?.email} mb={2}>
							<FormLabel fontSize={14} fontWeight={600}>Email ID</FormLabel>
							<Input
								border={"1px solid #D7D7D7"}
								type='email'
								placeholder="Please enter your email"
								autoComplete="off"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							{errors?.email && (
								<FormErrorMessage>{errors.email}</FormErrorMessage>
							)}
						</FormControl>

						<FormControl isInvalid={errors?.password} >
							<FormLabel fontSize={14} fontWeight={600}>Password</FormLabel>
							<InputGroup>
								<Input
									border={"1px solid #D7D7D7"}
									type={showPassword ? 'text' : 'password'}
									placeholder="Please enter your password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<InputRightElement cursor={"pointer"} onClick={() => setShowPassword(prev => !prev)}>
									<EyeIcon />
								</InputRightElement>
							</InputGroup>
							{errors.password && (
								<FormErrorMessage>{errors.password}</FormErrorMessage>
							)}
						</FormControl>

						<ChakraLink textAlign={"right"} color={"black"} fontSize={14} fontWeight={500} as={ReactRouterLink} to='/forgot-password'>
							Forget Password
						</ChakraLink>
					</Stack>

					<Button
						isLoading={loader}
						loadingText='Login in..'
						_hover={{ opacity: "80%" }}
						width={"100%"}
						background={"#4C8BF5"}
						color={"white"}
						fontWeight={700}
						fontSize={16}
						type="submit"
					>
						Login
					</Button>
				</form>
			</Flex>

			<Flex justifySelf={"flex-end"} pb={4} alignItems={"end"}>
				<Text fontSize={12} fontWeight={400} mr={2}>
					Powered by
				</Text>
				<Image
					src={`${process.env.PUBLIC_URL}/images/mounts-my.png`}
					alt='mounts-my-logo'
					style={{ width: '30px', marginRight: "8px" }}
				/>
				<Text fontSize={10} fontWeight={700}>
					MountsMy HealthCare
				</Text>
			</Flex>
		</Flex>
	);
};

export default Login;
