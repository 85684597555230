import { Box } from '@chakra-ui/react';
import Topbar from '../Topbar';
import PatientsTopbar from './PatientsTopbar';
import PatientList from './PatientList';
import { useEffect, useState } from 'react';
import api from '../../../api';

function Patients() {
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortColumn, setSortColumn] = useState('patientsrno'); // Default sort column
  const [sortDirection, setSortDirection] = useState('desc'); // Default sort direction
  const [patientStatus, setPatientStatus] = useState('');
  const [labWorkStatus, setLabWorkStatus] = useState('');

  const fetchData = async (params) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/patient/datatable', { params });
      if (response.data.status) {
        setPatients(response.data.response.data);
        setTotalPages(Math.ceil(response.data.response.recordsTotal / params.length));
        setTotalRecords(response.data.response.recordsTotal);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = {
      searchValue,
      length: 100, // Number of items per page
      start: (page - 1) * 100, // Calculate the starting index
      draw: page,
      status: patientStatus,
      labstatus:labWorkStatus,
      sortColumn,
      sortDirection,
    };

    fetchData(params);
  }, [page, searchValue, sortColumn, sortDirection,patientStatus,labWorkStatus]);

  const onSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    setPage(1); // Reset to the first page when search value changes
  };

  const onSort = (column, sortDirection) => {
    setSortColumn(column.sortField); // Ensure sortField is defined in columns
    setSortDirection(sortDirection);
    setPage(1); // Reset to the first page on sort change
  };

  useEffect(() => {
    setSortDirection('desc');
  }, [])
  

  return (
    <Box display="flex" flexDirection="column" w={"100%"} h={"100vh"} bg={"#F8FAFF"}>
      <Box as="header">
        <Topbar title="Patients" />
      </Box>

      <Box m={"0 16px 16px"} pt={"16px"} borderRadius="8px 8px 0px 0px" boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"} bg={"white"}>
        <PatientsTopbar onSearchValueChange={onSearchValueChange} searchValue={searchValue} />
        <PatientList
           patients={patients}
           setPatients={setPatients} // Optional if not needed
           page={page}
           setPage={setPage}
           totalPages={totalPages}
           totalRecords={totalRecords}
           onSort={onSort}
           fetchData={fetchData}
           sortColumn={sortColumn}
           sortDirection={sortDirection}
           setPatientStatus={setPatientStatus}
           setLabWorkStatus={setLabWorkStatus}
           patientStatus={patientStatus}
           labWorkStatus={labWorkStatus}

        />
      </Box>
    </Box>
  );
}

export default Patients;
