import { Box, Button, Flex, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react'
import React from 'react'
import SearchIcon from '../../../components/icons/SearchIcon'
import { useNavigate } from 'react-router-dom';
import FilterIcon from '../../../components/icons/FilterIcon';

function PatientsTopbar({ onSearchValueChange, searchValue }) {
  const navigate = useNavigate();

  return (
    <Flex p={"0px 16px 16px"} alignItems={"center"} justifyContent={"space-between"}>
      <Text color="#333" fontSize={"20px"} fontWeight={700}>{"Patient’s Information"}</Text>
      <Flex gap="16px" alignItems={"center"} justifyContent={"space-around"}>
        {/* <Box cursor={"pointer"}>
          <FilterIcon />
        </Box> */}
        <InputGroup w={"auto"}>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon size={"20px"} />
          </InputLeftElement>
          <Input 
            w={"244px"} 
            color={"#747474"} 
            _placeholder={{ fontSize: "12px" }} 
            placeholder='search by name, sdc, age,...' 
            border={"1px solid #4C8BF5"} 
            onChange={onSearchValueChange}
            value={searchValue}
          />
        </InputGroup>

        <Button
          background='#4C8BF5'
          color={"white"}
          _hover={{
            opacity: 0.9
          }}
          fontSize={"16px"}
          width={"157px"}
          h={"44px"}
          onClick={() => navigate("/patients/add-patient")}
        >
          Add Patient
        </Button>
      </Flex>
    </Flex>
  )
}

export default PatientsTopbar