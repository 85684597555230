import { Icon } from '@chakra-ui/react'

function CalendarBlank({ color = "#383838" }) {
    return (
        <Icon fontSize={24} color={color}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M20 3H17.75V2.25C17.75 2.05109 17.671 1.86032 17.5303 1.71967C17.3897 1.57902 17.1989 1.5 17 1.5C16.8011 1.5 16.6103 1.57902 16.4697 1.71967C16.329 1.86032 16.25 2.05109 16.25 2.25V3H8.75V2.25C8.75 2.05109 8.67098 1.86032 8.53033 1.71967C8.38968 1.57902 8.19891 1.5 8 1.5C7.80109 1.5 7.61032 1.57902 7.46967 1.71967C7.32902 1.86032 7.25 2.05109 7.25 2.25V3H5C4.60218 3 4.22064 3.15804 3.93934 3.43934C3.65804 3.72064 3.5 4.10218 3.5 4.5V19.5C3.5 19.8978 3.65804 20.2794 3.93934 20.5607C4.22064 20.842 4.60218 21 5 21H20C20.3978 21 20.7794 20.842 21.0607 20.5607C21.342 20.2794 21.5 19.8978 21.5 19.5V4.5C21.5 4.10218 21.342 3.72064 21.0607 3.43934C20.7794 3.15804 20.3978 3 20 3ZM7.25 4.5V5.25C7.25 5.44891 7.32902 5.63968 7.46967 5.78033C7.61032 5.92098 7.80109 6 8 6C8.19891 6 8.38968 5.92098 8.53033 5.78033C8.67098 5.63968 8.75 5.44891 8.75 5.25V4.5H16.25V5.25C16.25 5.44891 16.329 5.63968 16.4697 5.78033C16.6103 5.92098 16.8011 6 17 6C17.1989 6 17.3897 5.92098 17.5303 5.78033C17.671 5.63968 17.75 5.44891 17.75 5.25V4.5H20V7.5H5V4.5H7.25ZM20 19.5H5V9H20V19.5Z" fill="#5A5A5A" />
            </svg>
        </Icon>
    )
}

export default CalendarBlank
