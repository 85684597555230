import { extendTheme } from '@chakra-ui/react'
import '@fontsource/open-sans'
import '@fontsource/oxygen'

const theme = extendTheme({
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`,
    table: `'oxygen', 'Open Sans', sans-serif`, // Custom font for table
  },
  components: {
    Table: {
      border: "#E8E8E8",
      variants: {
        simple: {
            // table: {
            //   borderColor: "#E8E8E8"
            // },
            th: {
                borderColor: "#E8E8E8"
            },
            td: {
                borderColor: "#E8E8E8"
            }
        }
      },
      baseStyle: {
        th: {
          fontFamily: `oxygen, 'Open Sans', sans-serif`,
          fontWeight: 700,
          fontSize: '16px',
          textTransform: 'none',
        },
        td: {
          fontFamily: `'oxygen', 'Open Sans', sans-serif`,
          fontSize: '16px',
          fontWeight: 400
        },
      },
    },
  },
})

export default theme