import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Topbar from '../Topbar'

import PatientStaticInfo from './PatientStaticInfo'
import { useParams } from 'react-router-dom'
import api from '../../../api'
import PatientSummary2 from './PatientSummary2'

function PatientSummaryInfo() {
  const param = useParams();
  const [patient, setPatient] = useState();
  const [patientSummary, setPatientSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDataPatientInfo = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/patient/${id}`);
      if (response.data.status) {
        setPatient(response.data.response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataPatientSummaryInfo = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/patient/${id}/summary/list`);
      if (response.data.status) {
        setPatientSummary(prev => ([...response.data.response, {
          "patientSummaryId": 0,
          "summaryDate": new Date(),
          "treatment": "",
          "medicine": "",
          "doctorId": 0,
          "patientId": parseInt(param.id),
          "doctorName": "",
          "dueAmount": 0,
          "treatmentStatus": "Incompleted"
        }]));
      } else {
        setPatientSummary(prev => ([{
          "patientSummaryId": 0,
          "summaryDate": new Date(),
          "treatment": "",
          "medicine": "",
          "doctorId": 0,
          "patientId": parseInt(param.id),
          "doctorName": "",
          "dueAmount": 0,
          "treatmentStatus": "Incompleted"
        }]));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataPatientInfo(param.id);
    fetchDataPatientSummaryInfo(param.id);
  }, [param.id]);

  return (
    <Box display="flex" flexDirection="column" w={"100%"} h={"100vh"}>
      <Box
        as="header"
        position="sticky"
        top="0"
        zIndex="1"
        bg={"white"}
      >
        <Topbar title="Patients" />
      </Box>

      {/* Title Searchbar and Add Patient Button */}
      <Box m={"0 16px 16px"} borderRadius="8px 8px 0px 0px" boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}>
        <PatientStaticInfo patient={patient} />

        <Box m={"24px"} pb={16} boxShadow={"none"}>
          <PatientSummary2
            patientSummary={patientSummary}
            id={parseInt(param.id)}
            fetchDataPatientSummaryInfo={fetchDataPatientSummaryInfo}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PatientSummaryInfo