import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Badge, Divider, Button } from '@chakra-ui/react';
import moment from 'moment';
import '../../../global/css/patient-info.css';
import EditIcon from '../../../components/icons/EditIcon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const PatientStaticInfo = ({ patient = null }) => {

  const param = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isPatientMenuEditable, setIsPatientMenuEditable] = useState(false);
  useEffect(() => {
    // Check if the URL matches the pattern /patients/${id}/edit
    if (location.pathname === `/patients/${param.id}/case`) {
      setIsPatientMenuEditable(true)
    }
  }, [param.id, location.pathname]); // Dependencies: id and pathname
  const navigateToEditPage = (id) => {
    navigate(`/patients/${id}/edit`);
    // navigate(`/patients`);
    // alert("This functionality is under development")
  };

  if (!patient) {
    return null
  }
 
  
  return (
    <Box p={0} pb={0} bg="white" borderRadius="md">
      <Box>
        <Flex justifyContent={"space-between"} alignItems={"center"} p={"0px 0 16px 20px"} borderBottom="1px solid #E8E8E8">
          <Text fontSize="20px" fontWeight="700" color={"#333"} fontFamily={"Oxygen"}>
            Patient's Information
          </Text>
          {
            ((isPatientMenuEditable) ? (<Button mr={2} variant={"outline"} onClick={() => (navigateToEditPage(patient.patientId))}>
            <Box mr={"8px"}>
              <EditIcon />
            </Box>
            <Text color={"#3F3F46"} fontWeight={400} fontSize={"16px"} >Edit</Text>
          </Button>) : (<p></p>))
          }
        </Flex>
      </Box>
      <Box p={3}>
        <table className='patient-info-table table-bordered'>
          <tbody>
            <tr>
              <td className='table-lable'>Name</td>
              <td>
                <p className='table-content'> {`${patient.patientFirstname} ${patient.patientLastname}`} </p>
              </td>
              <td className='table-lable'>D.O.B.</td>
              <td>
                <p className='table-content'>{moment(patient.patientDob).format("DD-MM-yyyy")} </p>
              </td>
              <td className='table-lable'  style={{width:'9%'}}>SDC No.</td>
              <td className='table-content'>
                <p className='table-content'> {patient.patientPrefix}{patient.patientId} </p>
              </td>
            </tr>
            <tr>
              <td className='table-lable'>Age</td>
              <td>
                <p className='table-content'> {patient.patientAge} </p>
              </td>
              <td className='table-lable'>Email</td>
              <td>
                <p className='table-content'> {patient.patientEmail} </p>
              </td>
              <td className='table-lable'>Date.</td>
              <td className='table-content'>
                <p className='table-content'> {moment(patient.createdOn).format("DD-MM-yyyy")} </p>
              </td>
            </tr>
            <tr>
              <td className='table-lable'>Address</td>
              <td colSpan={3} style={{width:'60%'}}>
                <p className='table-content'> {patient.patientAddressLine1} {patient.patientAddressLine2} &nbsp; </p>
              </td>
              <td className='table-lable'>Ref. by</td>
              <td className='table-content'>
                <p className='table-content'> {patient.referenceBy}&nbsp; </p>
              </td>
            </tr>
          </tbody>
        </table>
        <table className='contact-details-table table-bordered'>
          <thead>
          <tr>
            <th colSpan={4}>Patient Contact Details</th>
          </tr>
          {patient?.patientContactDetailsVos?.map((patientContactDetailsVo, index) => (  
              <tr data-index={index}>
                <th>Mobile No.</th>
                <td>{patientContactDetailsVo.patientContactMobileNo}</td>
                <th>Belongs to</th>
                <td>
                  <span>{((patientContactDetailsVo.patientContactBelongsTo).toUpperCase() )}</span>
                  <span>{((patientContactDetailsVo.patientContactBelongsTo == 'other') ? (" : " + patientContactDetailsVo.patientContactOther) : (""))}</span>
                </td>
              </tr>
            ))}  
          </thead>          
        </table>
      </Box>      
    </Box>
  );
};

export default PatientStaticInfo;
