import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import Sidebar from "./Sidebar";

function DashboardLayout({ children }) {
  

  return (
    <Flex w={"100vw"} h={"100vh"}>
      <Sidebar />
      <Box flex={1} overflowX={"hidden"} background={"transparent"}>
        {children}
        
      </Box>
    </Flex>
  );
}

export default DashboardLayout;
