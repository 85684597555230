import React, { useEffect, useState } from "react";
import Badge from "../../../components/Badge";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import "../../../global/css/patient-list.css";
import api from "../../../api";
import Topbar from "../Topbar";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Link,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import SearchIcon from "../../../components/icons/SearchIcon";
import FilterIcon from "../../../components/icons/FilterIcon";
import CashIcon from "../../../components/icons/CashIcon";
import OnlineIcon from "../../../components/icons/OnlineIcon";
import { FaFilter } from "react-icons/fa6";

const TransactionList = () => {
  const [transaction, setTransaction] = useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortColumn, setSortColumn] = useState("patientno"); // Default sort column
  const [sortDirection, setSortDirection] = useState("asc"); // Default sort direction
  const [payStatus, setPayStatus] = useState('');
  const [payMethod, setPayMethod] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const params = {
      searchValue: searchValue,
      length: 10, // Number of items per page
      start: (page - 1) * 10, // Calculate the starting index
      draw: page,
      status: payStatus,
      sortColumn: sortColumn,
      paymentmode: payMethod,
      sortDirection: sortDirection,
    };

    getTransactionData(params);
  }, [page, searchValue, sortColumn, sortDirection, payMethod, payStatus]);

  const onSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    setPage(1); // Reset to the first page when search value changes
  };

  const onSort = (column, direction) => {
    setSortColumn(column.sortField);
    setSortDirection(direction);
    setPage(1); // Reset to the first page on sort change
  };

  const getTransactionData = async (params) => {
    try {
      const response = await api.get("/payment/transaction/datatable", { params });
      if (response.data.status) {
        setTransaction(response.data.response.data);
        setTotalPages(
          Math.ceil(response.data.response.recordsTotal / params.length)
        );
        setTotalRecords(response.data.response.recordsTotal);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const navigateToCasePaper = (id) => {
    navigate(`/transaction/${id}/case`);
  };

  const customPatientNoSort = (rowA, rowB) => {
    return rowA.transactionrno - rowB.transactionrno;
  };

  const customPatientNameSort = (rowA, rowB) => {
    const a = `${rowA.patientfirstname} ${rowA.patientlastname}`.toLowerCase();
    const b = `${rowB.patientfirstname} ${rowB.patientlastname}`.toLowerCase();
    return a.localeCompare(b);
  };

  const customPatientAgeSort = (rowA, rowB) => {
    return rowA.patientage - rowB.patientage;
  };

  const customPatientAppointmentDateSort = (rowA, rowB) => {
    return new Date(rowA.appointmentdate) - new Date(rowB.appointmentdate);
  };

  const [perPage, setPerPage] = useState(10);

  const onRowsPerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page on rows per page change
  };

  useEffect(() => {
    const params = {
      start: (page - 1) * perPage, // Calculate starting index
      length: perPage, // Number of items per page
      // other parameters like searchValue if applicable
      start: (page - 1) * 10, // Calculate the starting index
      draw: page, // Current page number
      status: payStatus, // Status filter, if needed
      paymentmode: payMethod
    };
    getTransactionData(params); // Trigger data fetching with new pagination parameters
  }, [page, perPage]);


  const TransactionStatusFilterHeader = ({ onClick }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Pay Status</span>

      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FaFilter />}
          size="sm"
          ml={1}
        //  variant="outline"
        />
        <MenuList>
          <MenuItem onClick={() => handleFilterClick('status', '')}>All</MenuItem>
          <MenuItem onClick={() => handleFilterClick('status', 'clear')}>Clear</MenuItem>
          <MenuItem onClick={() => handleFilterClick('status', 'due')}>Due</MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
  
  const TransactionPayFilterHeader = ({ onClick }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Pay Method</span>

      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FaFilter />}
          size="sm"
          ml={1}
        //  variant="outline"
        />
        <MenuList>
          <MenuItem onClick={() => handleFilterClick('method', '')}>All</MenuItem>
          <MenuItem onClick={() => handleFilterClick('method', 'online')}>Online</MenuItem>
          <MenuItem onClick={() => handleFilterClick('method', 'cash')}>Cash</MenuItem>
        </MenuList>
      </Menu>
    </div>
  );

  const handleFilterClick = (type, filtervalue) => {
    // Implement your filter logic here
    console.log('Filter icon clicked');
    // setPerPage(newPerPage);
    if (type == "status") {
      setPayStatus(filtervalue)
    } else if (type == 'method') {
      setPayMethod(filtervalue)
    } else {
      setPayMethod('')
      setPayStatus('')
    }
  };


  const columns = [
    {
      name: "Patient's ID",
      className: "table-header-title",
      selector: (row) => {
        let hrefLink = `/transaction/${row.paymentTransactionId}`;
        return (
          <Link href={hrefLink}>
            <p style={{ color: "#4C8BF5", fontWeight: 600 }}>{row.patientNo}</p>
          </Link>
        );
      },
      sortField: "paymenttransactionno",
      sortable: true,
    },
    {
      name: "Patient's Name",
      className: "table-header-title",
      selector: (row) => `${row.patientName}`,
      sortable: true,
      sortField: "patientname",
    },
    {
      name: "Phone",
      className: "table-header-title",
      selector: (row) => row.patientMobileno,
      center: true,
      sortable: true,
      sortField: "patientsrno"
    },
    {
      name: "Fees",
      className: "table-header-title",
      selector: (row) => row.totalFees,
      sortable: true,
      center: true,
      sortField: 'totalfees'
    },
    {
      name: "Paid",
      className: "table-header-title",
      selector: (row) => row.paidAmount,
      sortable: true,
      center: true,
      sortField: 'paidamount'
    },
    {
      name: <TransactionPayFilterHeader />,
      className: "table-header-title",
      selector: (row) => {
        if (row.paymentMode) {
          if (row.paymentMode == "online") {
            return <OnlineIcon label={'Online'} />
          } else {
            return <CashIcon label={"Cash"} />
          }
        } else {
          return "-"
        }
      },
      sortable: false,
      center: false,
    },
    {
      name: "Assignee",
      className: "table-header-title",
      selector: (row) => row.assignee || "-",
      sortable: false,
      center: true,
    },
    {
      name: <TransactionStatusFilterHeader />,
      className: "table-header-title",
      selector: (row) => <Badge status={row.paymentStatus} />,
      center: true,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      w={"100%"}
      h={"100vh"}
      bg={"#F8FAFF"}
    >
      <Box as="header">
        <Topbar title="Transactions" />
      </Box>
      <Box
        m={"0 16px 16px"}
        pt={"16px"}
        borderRadius="8px 8px 0px 0px"
        boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
        bg={"white"}
      >
        <Flex
          p={"0px 16px 16px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text color="#333" fontSize={"20px"} fontWeight={700}>
            {"Transaction's List"}
          </Text>
          <Flex
            gap="16px"
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <InputGroup w={"auto"}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon size={"20px"} />
              </InputLeftElement>
              <Input
                w={"244px"}
                color={"#747474"}
                _placeholder={{ fontSize: "12px" }}
                placeholder="search by name, sdc, age,..."
                border={"1px solid #4C8BF5"}
                onChange={onSearchValueChange}
                value={searchValue}
              />
            </InputGroup>
            {/* <Box cursor={"pointer"}>
              <FilterIcon />
            </Box> */}
          </Flex>
        </Flex>
        <DataTable
          columns={columns}
          data={transaction}
          direction="auto"
          fixedHeader
          fixedHeaderScrollHeight="auto"
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRecords} // Total records across all pages
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[50, 100, 500, 1000]}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={onRowsPerPageChange}
          onSort={onSort}
          sortServer
          sortField={sortColumn}
          sortDirection={sortDirection}
          selectableRows
          noDataComponent={
            <Box p={5} m={5}>
              No data available, Please reload to view all data...!!!
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default TransactionList;
