import { Global, css } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={css`
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      /* Firefox scrollbar styles */
      * {
        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1;
      }
      *::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      *::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
      *::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 3px solid #f1f1f1;
      }
      .SpreadsheetGrid__headCell{
        background: transparent;
        color: black;
        font-size: 14px;
        font-weight: 700;
      }
      .SpreadsheetGridContainer, .SpreadsheetGrid__headCell, .SpreadsheetGrid__cell {
        border: 1px solid lightgrey;
        border-collapse: collapse;
      }
      .SpreadsheetGrid__cell_active {
        box-shadow: inset 0 0 0 2px lightblue;
      }
      .SpreadsheetGridDropdown {
        color: black;
      }
    `}
  />
);

export default GlobalStyles;
