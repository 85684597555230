import { Icon } from '@chakra-ui/react'

function SearchIcon({ color = "#747474", size = "24px" }) {
  return (
    <Icon fontSize={size} color={color}>
      <path id="Vector" d="M19.3013 18.4794L14.6073 13.7862C15.9678 12.1528 16.6462 10.0578 16.5014 7.93692C16.3566 5.81605 15.3998 3.83266 13.8299 2.39933C12.26 0.965999 10.1979 0.193093 8.07263 0.241393C5.94738 0.289692 3.92256 1.15548 2.41939 2.65865C0.916222 4.16182 0.0504346 6.18664 0.0021347 8.31189C-0.0461652 10.4371 0.726741 12.4992 2.16007 14.0691C3.5934 15.639 5.5768 16.5959 7.69766 16.7407C9.81853 16.8855 11.9136 16.2071 13.547 14.8465L18.2401 19.5406C18.3098 19.6103 18.3925 19.6656 18.4836 19.7033C18.5746 19.741 18.6722 19.7604 18.7707 19.7604C18.8693 19.7604 18.9669 19.741 19.0579 19.7033C19.1489 19.6656 19.2317 19.6103 19.3013 19.5406C19.371 19.4709 19.4263 19.3882 19.464 19.2972C19.5017 19.2061 19.5211 19.1085 19.5211 19.01C19.5211 18.9114 19.5017 18.8139 19.464 18.7228C19.4263 18.6318 19.371 18.549 19.3013 18.4794ZM1.52072 8.50998C1.52072 7.17496 1.9166 5.86991 2.6583 4.75988C3.4 3.64985 4.45421 2.78468 5.68761 2.27379C6.92101 1.7629 8.27821 1.62923 9.58758 1.88968C10.897 2.15013 12.0997 2.793 13.0437 3.73701C13.9877 4.68101 14.6306 5.88375 14.891 7.19312C15.1515 8.50249 15.0178 9.85969 14.5069 11.0931C13.996 12.3265 13.1309 13.3807 12.0208 14.1224C10.9108 14.8641 9.60575 15.26 8.27072 15.26C6.48112 15.258 4.76538 14.5462 3.49994 13.2808C2.2345 12.0153 1.52271 10.2996 1.52072 8.50998Z" fill="currentColor"/>
    </Icon>
  )
}

export default SearchIcon