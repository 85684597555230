import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Icon,
    VStack,
    Text,
    HStack,
    useDisclosure,
    IconButton,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import React from "react";

const ConfirmationModal = ({ isOpen, onClose, onYes }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent maxW="sm" borderRadius="lg" p={4}>
                <ModalHeader textAlign="center">
                    <VStack>
                        <Icon as={FiTrash2} boxSize={10} color="red.500" bg="red.50" p={2} borderRadius="full" />
                        <Text fontSize="lg" fontWeight="bold">
                            Are you sure?
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            You want to delete this appointment
                        </Text>
                    </VStack>
                </ModalHeader>

                <ModalFooter justifyContent="center">
                    <HStack spacing={4}>
                        <Button variant="outline" colorScheme="blue" onClick={onClose}>
                            No
                        </Button>
                        <Button colorScheme="red" onClick={onYes}>
                            Yes
                        </Button>
                    </HStack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;
