import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { logout } from '../features/Auth/Login/loginSlice';
import { jwtTokenValidate } from '../utils/jwtTokenValidate';
import DashboardLayout from '../components/DashboardLayout';
import { login } from '../store/auth/authSlice';

const PrivateRoute = ({ element: Element, roles, ...rest }) => {

  const { isAuthenticated, role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // const token = "faketoken";
  // const userInfo = {
  //   role : "admin"
  // }
  const token = localStorage.getItem('access_token');
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem("userInfo")) : null;

  // useEffect(() => {
  //   // CALL CURRENT USER API
  //   dispatch(login({
  //     token: token,//response.token,
  //     email: "email",
  //     role: "admin",
  //     userId: "1"
  //   }))
  // }, [])


  if (!token || !jwtTokenValidate(token) || !roles.includes(userInfo.role)) {
    localStorage.removeItem('access_token');
    // dispatch(logout());
    return <Navigate to="/" />;
  }

  // return isAuthenticated && roles.includes(role) ? (
  //   <Element {...rest} />
  // ) : (
  //   <Navigate to="/" />
  // );

  return <DashboardLayout>
    <Element {...rest} />
  </DashboardLayout>
};

export default PrivateRoute;
