import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DataTable from "react-data-table-component";
import "../../../global/css/patient-list.css";
import { Box, IconButton, Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import EditIcon from "../../../components/icons/EditIcon";
import Badge from "../../../components/Badge";
import { FaFilter } from "react-icons/fa6";
import LabWorkStatusBadge from "../../../components/LabWorkStatusBadge";

const PatientList = ({
  patients,
  setPatients,
  page,
  setPage,
  totalRecords,
  fetchData,
  onSort,
  sortColumn,
  sortDirection,
  setPatientStatus,
  setLabWorkStatus,
  patientStatus,
  labWorkStatus
}) => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(100);

  // const statusFilterDiv = () => {
  //   return (
  //     // <Menu>
  //     //   <MenuButton
  //     //     as={IconButton}
  //     //     aria-label="Options"
  //     //     icon={<HamburgerIcon />}
  //     //     variant="outline"
  //     //   />
  //     //   <MenuList>
  //     //     <MenuItem>Active</MenuItem>
  //     //     <MenuItem>Inactive</MenuItem>
  //     //   </MenuList>
  //     // </Menu>
  //   );
  // };

  const LabStatusFilterHeader = ({ onClick }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Lab Status</span>
      <Menu style={{width:'10%'}}>
        <MenuButton
           as={IconButton}
           aria-label="Options"
           icon={<FaFilter />}
           size="sm"
           ml={1}
          //  variant="outline"
         />
         <MenuList>
           <MenuItem onClick={()=>handleFilterClick('labstatus','')}>All</MenuItem>
           <MenuItem onClick={()=>handleFilterClick('labstatus','complete')}>Complete</MenuItem>
           <MenuItem onClick={()=>handleFilterClick('labstatus','incomplete')}>Incomplete</MenuItem>
         </MenuList>
      </Menu>
    </div>
  );

  const PatientStatusFilterHeader = ({ onClick }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Status</span>

      <Menu>
        <MenuButton
           as={IconButton}
           aria-label="Options"
           icon={<FaFilter />}
           size="sm"
           ml={1}
          //  variant="outline"
         />
         <MenuList>
            <MenuItem onClick={()=>handleFilterClick('status','')}>All</MenuItem>
           <MenuItem onClick={()=>handleFilterClick('status','active')}>Active</MenuItem>
           <MenuItem onClick={()=>handleFilterClick('status','inactive')}>Inactive</MenuItem>
         </MenuList>
      </Menu>
    </div>
  );

  const handleFilterClick = (type,filtervalue) => {
    // Implement your filter logic here
    console.log('Filter icon clicked');
    // setPerPage(newPerPage);
    if(type == "status"){
      setPatientStatus(filtervalue)
    }else if(type == 'labstatus'){
      setLabWorkStatus(filtervalue)
    }else{
      setPatientStatus('')
      setLabWorkStatus('')
    }
  };

  const onCheckBoxSelect = (id, selected) => {
    if (id === "all") {
      setPatients((prev) => prev.map((s) => ({ ...s, selected: selected })));
    } else {
      setPatients((prev) =>
        prev.map((s) =>
          s.patientno === id ? { ...s, selected: !s.selected } : s
        )
      );
    }
  };

  const navigateToCasePaper = (id) => {
    navigate(`/patients/${id}/case`);
  };

  const onRowsPerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page on rows per page change
    fetchData({
      start: 0, // Reset to first page
      length: newPerPage,
      sortColumn,
      sortDirection,
      draw: page,
      status:patientStatus,
      labstatus:labWorkStatus
    });
  };

  useEffect(() => {
    const params = {
      start: (page - 1) * perPage,
      length: perPage,
      sortColumn,
      sortDirection,
      draw: page,
      status:patientStatus,
      labstatus:labWorkStatus
    };
    fetchData(params);
  }, [page, perPage, sortColumn, sortDirection]);

  const columns = [
    {
      name: "Patient's ID",
      className: "table-header-title",
      selector: (row) => {
        if (!row || !row.patientid || !row.patientno) {
          return <p style={{ color: "red" }}>N/A</p>;
        }
        const hrefLink = `/patients/${String(row.patientid)}/case`;
        return (
          <Link href={hrefLink}>
            <p style={{ color: "#4C8BF5", fontWeight: 600 }}>
              {String(row.patientno)}
            </p>
          </Link>
        );
      },
      sortable: true,
      sortField: "patientsrno",
      width: '140px',
    },
    {
      name: "Patient's Name",
      className: "table-header-title",
      selector: (row) => `${row.patientfirstname} ${row.patientlastname}`,
      sortable: true,
      sortField: "patientname",
      // width: '240px',
    },
    {
      name: "Age",
      className: "table-header-title",
      selector: (row) => row.patientage,
      center: true,
      sortable: true,
      sortField: "patientage",
      // width: '90px',
    },
    {
      name: "Phone",
      className: "table-header-title",
      selector: (row) => row.patientmobileno,
      center: true,
      // width: '120px',
    },
    {
      name: "Date",
      className: "table-header-title",
      selector: (row) => moment(row.appointmentdate).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
      sortField: "appointmentdate",
      // width: '200px',
    },
    {
      name: <PatientStatusFilterHeader />,
      className: "table-header-title",
      selector: (row) => <Badge status={row.status} />,
      center: true,
      // width: '170px',
    },
    {
      name: <LabStatusFilterHeader />,
      className: "table-header-title",
      selector: (row) => <LabWorkStatusBadge status={row.labstatus} />,
      center: true,
      width: '170px',
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={patients}
      direction="auto"
      fixedHeader
      fixedHeaderScrollHeight="auto"
      highlightOnHover
      pagination
      paginationServer
      paginationTotalRows={totalRecords}
      paginationPerPage={perPage}
      paginationRowsPerPageOptions={[50, 100, 500, 1000]}
      onChangePage={setPage}
      onChangeRowsPerPage={onRowsPerPageChange}
      onSort={onSort}
      sortServer
      sortField={sortColumn}
      sortDirection={sortDirection}
      selectableRows
      noDataComponent={<Box p={5} m={5}>No data available, Please reload to view all data...!!!</Box>}
    />
  );
};

export default PatientList;
