import React, { useEffect, useState } from "react";
import Badge from "../../components/Badge";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import "../../global/css/patient-list.css";
import api from "../../api";
import Topbar from "../dashboard/Topbar";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Link,
} from "@chakra-ui/react";
import SearchIcon from "../../components/icons/SearchIcon";
import FilterIcon from "../../components/icons/FilterIcon";
import CashIcon from "../../components/icons/CashIcon";
import OnlineIcon from "../../components/icons/OnlineIcon";
import AddUserModal from "./AddUserModel";
import { toast } from "react-toastify";
import PlusSquareIcon from "../../components/icons/PlusSquareIcon";
import UserGear from "../../components/icons/UserGear";
import './UserList.css'
import Stethoscope from "../../components/icons/Stethoscope";
import Syringe from "../../components/icons/Syringe";

const UsersList = () => {
  const [user, setUser] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortColumn, setSortColumn] = useState("name"); // Default sort column
  const [sortDirection, setSortDirection] = useState("asc"); // Default sort direction
  const [addUserModel, setAddUserModel] = useState(false); // Open model for add User
  const navigate = useNavigate();

  useEffect(() => {
    const params = {
      searchValue: searchValue,
      length: 10, // Number of items per page
      start: (page - 1) * 10, // Calculate the starting index
      draw: page,
      status: "",
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    };

    getUsersData(params);
  }, [page, searchValue, sortColumn, sortDirection]);

  useEffect(() => {
    getRoleDataList();
  }, [])

  const onSearchValueChange = (e) => {
    setSearchValue(e.target.value);
    setPage(1); // Reset to the first page when search value changes
  };

  const onSort = (column, direction) => {
    setSortColumn(column.sortField);
    setSortDirection(direction);
    setPage(1); // Reset to the first page on sort change
  };

  const getUsersData = async (params) => {
    try {
      const response = await api.get("/user/datatable", { params });
      if (response.data.status) {
        setUser(response.data.response.data);
        setTotalPages(
          Math.ceil(response.data.response.recordsTotal / params.length)
        );
        setTotalRecords(response.data.response.recordsTotal);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRoleDataList = async () => {
    try {
      const response = await api.get("/user/role/getall");
      if (response.data.status) {
        setRoleList(response.data.response)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [perPage, setPerPage] = useState(10);

  const onRowsPerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page on rows per page change
  };

  const handleAddUser = async (data) => {
    let dataParam = {
      ...data,
      userFrontId: null,
      addressLine1: "",
      addressLine2: "",
      countryCode: "",
      stateCode: "",
      cityCode: "",
      pincode: "",
    }
    try {
      const result = await api.post(`/user/save`, dataParam);
      console.log("result ", result.data);
      if (result.data.status) {
        toast.success("User Successfully Added!");
        setPage(1);
        const params = {
          start: (1 - 1) * perPage,
          length: perPage,
          start: (1 - 1) * 10,
          draw: 1,
          status: "",
        };
        getUsersData(params);
        setAddUserModel(false);
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      toast.error("Something went wrong!");
    }
  }

  useEffect(() => {
    const params = {
      start: (page - 1) * perPage, // Calculate starting index
      length: perPage, // Number of items per page
      // other parameters like searchValue if applicable
      start: (page - 1) * 10, // Calculate the starting index
      draw: page, // Current page number
      status: "", // Status filter, if needed
    };
    getUsersData(params); // Trigger data fetching with new pagination parameters
  }, [page, perPage]);


  const columns = [
    // {
    //   name: "ID",
    //   className: "table-header-title",
    //   selector: (row) => {
    //     let hrefLink = `/transaction/${row.userFrontId}`;
    //     return (
    //       <Link href={hrefLink}>
    //         <p style={{ color: "#4C8BF5", fontWeight: 600 }}>{row.userFrontId}</p>
    //       </Link>
    //     );
    //   },
    //   sortable: true,
    //   sortFunction: customPatientNoSort,
    // },
    {
      name: "Name",
      className: "table-header-title",
      selector: (row) => `${row?.name}`,
      sortable: true,
      sortField: 'name',
      center: true,
    },
    {
      name: "Phone",
      className: "table-header-title",
      selector: (row) => row?.contactNo,
      sortable: true,
      center: true,
      sortField: 'contactNo'
    },
    {
      name: "Email ID",
      className: "table-header-title",
      selector: (row) => row?.email,
      sortable: true,
      center: true,
      sortField: 'email'
    },
    {
      name: "Type",
      className: "table-header-title",
      selector: (row) => {
        if (row?.userType) {
          if (row?.userType == "doctor") {
            return <button className="table-list-doctor"> <Stethoscope /> Doctor</button>
          } else if (row?.userType == "staff") {
            return <button className="table-list-staff"> <Syringe /> Staff</button>
          } else {
            return row?.userType
          }
        } else {
          return row?.userType
        }
      },
      sortable: false,
      center: false,
    },
    {
      name: "Roles",
      className: "table-header-title",
      selector: (row) => row.userType,
      sortable: false,
      center: true,
    },
    // {
    //   name: "Assignee",
    //   className: "table-header-title",
    //   selector: (row) => row.assignee || "-",
    //   sortable: false,
    //   center: true,
    // },
    {
      name: "Status",
      className: "table-header-title",
      selector: (row) => <Badge status={row.status} />,
      center: true,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      w={"100%"}
      h={"100vh"}
      bg={"#F8FAFF"}
    >
      <Box as="header">
        <Topbar title="Users" />
      </Box>
      <Box
        m={"0 16px 16px"}
        pt={"16px"}
        borderRadius="8px 8px 0px 0px"
        boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
        bg={"white"}
      >
        <Flex
          p={"0px 16px 16px"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text color="#333" fontSize={"20px"} fontWeight={700}>
            {"User's List"}
          </Text>
          <Flex
            gap="16px"
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <Box cursor={"pointer"} onClick={() => setAddUserModel(true)}>
              <PlusSquareIcon />
            </Box>
            <InputGroup w={"auto"}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon size={"20px"} />
              </InputLeftElement>
              <Input
                w={"244px"}
                color={"#747474"}
                _placeholder={{ fontSize: "12px" }}
                placeholder="search by name, sdc, age,..."
                border={"1px solid #4C8BF5"}
                onChange={onSearchValueChange}
                value={searchValue}
              />
            </InputGroup>
            <Box cursor={"pointer"}>
              <FilterIcon />
            </Box>
            <Box cursor={"pointer"}>
              <UserGear />
            </Box>
          </Flex>
        </Flex>
        <DataTable
          columns={columns}
          data={user}
          direction="auto"
          fixedHeader
          fixedHeaderScrollHeight="auto"
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRecords} // Total records across all pages
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 25, 50]}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={onRowsPerPageChange}
          onSort={onSort}
          sortServer
          sortField={sortColumn}
          sortDirection={sortDirection}
          selectableRows
          noDataComponent={
            <Box p={5} m={5}>
              No data available, Please reload to view all data...!!!
            </Box>
          }
        />
      </Box>
      {addUserModel && (
        <AddUserModal
          isOpen={addUserModel}
          onClose={() => setAddUserModel(false)}
          handleAdd={(data) => handleAddUser(data)}
          roleList={roleList}
        />
      )}
    </Box>
  );
};

export default UsersList;
