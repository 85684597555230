import { Icon } from '@chakra-ui/react'

function PlusSquareIcon({ color = "black", ml = "0" }) {
  return (
    <Icon fontSize={24} color={color} ml={ml}>
      <path d="M19.5 3.5H4.5C4.10218 3.5 3.72064 3.65804 3.43934 3.93934C3.15804 4.22064 3 4.60218 3 5V20C3 20.3978 3.15804 20.7794 3.43934 21.0607C3.72064 21.342 4.10218 21.5 4.5 21.5H19.5C19.8978 21.5 20.2794 21.342 20.5607 21.0607C20.842 20.7794 21 20.3978 21 20V5C21 4.60218 20.842 4.22064 20.5607 3.93934C20.2794 3.65804 19.8978 3.5 19.5 3.5ZM19.5 20H4.5V5H19.5V20ZM16.5 12.5C16.5 12.6989 16.421 12.8897 16.2803 13.0303C16.1397 13.171 15.9489 13.25 15.75 13.25H12.75V16.25C12.75 16.4489 12.671 16.6397 12.5303 16.7803C12.3897 16.921 12.1989 17 12 17C11.8011 17 11.6103 16.921 11.4697 16.7803C11.329 16.6397 11.25 16.4489 11.25 16.25V13.25H8.25C8.05109 13.25 7.86032 13.171 7.71967 13.0303C7.57902 12.8897 7.5 12.6989 7.5 12.5C7.5 12.3011 7.57902 12.1103 7.71967 11.9697C7.86032 11.829 8.05109 11.75 8.25 11.75H11.25V8.75C11.25 8.55109 11.329 8.36032 11.4697 8.21967C11.6103 8.07902 11.8011 8 12 8C12.1989 8 12.3897 8.07902 12.5303 8.21967C12.671 8.36032 12.75 8.55109 12.75 8.75V11.75H15.75C15.9489 11.75 16.1397 11.829 16.2803 11.9697C16.421 12.1103 16.5 12.3011 16.5 12.5Z" fill="currentColor"/>
    </Icon>
  )
}

export default PlusSquareIcon