import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Topbar from "../Topbar";
import PatientCasePaper from "./PatientCasePaper";
import PatientStaticInfo from "./PatientStaticInfo";
import PatientSummary2 from './PatientSummary2'
import { useParams } from "react-router-dom";
import api from "../../../api";
import '../../../components/Sidebar/sidebar.css';

function PatientCase() {
  const param = useParams();
  const [patient, setPatient] = useState([]);
  const [patientSummary, setPatientSummary] = useState([]);
  const [patientCaseInfo, setPatientCaseInfo] = useState([
    {
      casePaperId: 0,
      familyDoctorName: "",
      complain: "",
      historyOff: "",
      onExamination: "",
      toothChart: [
        {
          toothChartType: "missing",
          toothChartMode: "123",
          toothChartId: 0,
          upperRowLeft: "",
          upperRowRight: "",
          lowerRowLeft: "",
          lowerRowRight: "",
          casePaperId: 0,
        },
        {
          toothChartType: "roots",
          toothChartMode: "123",
          toothChartId: 0,
          upperRowLeft: "",
          upperRowRight: "",
          lowerRowLeft: "",
          lowerRowRight: "",
          casePaperId: 0,
        },
        {
          toothChartType: "fill",
          toothChartMode: "123",
          toothChartId: 0,
          upperRowLeft: "",
          upperRowRight: "",
          lowerRowLeft: "",
          lowerRowRight: "",
          casePaperId: 0,
        },
        {
          toothChartType: "fcc",
          toothChartMode: "123",
          toothChartId: 0,
          upperRowLeft: "",
          upperRowRight: "",
          lowerRowLeft: "",
          lowerRowRight: "",
          casePaperId: 0,
        },
        {
          toothChartType: "bridge",
          toothChartMode: "123",
          toothChartId: 0,
          upperRowLeft: "",
          upperRowRight: "",
          lowerRowLeft: "",
          lowerRowRight: "",
          casePaperId: 0,
        },
        {
          toothChartType: "implant",
          toothChartMode: "123",
          toothChartId: 0,
          upperRowLeft: "",
          upperRowRight: "",
          lowerRowLeft: "",
          lowerRowRight: "",
          casePaperId: 0,
        },
      ],
      treatmentAdvice: "",
      treatmentTodo: "",
      additionalNotes: "",
      labWorks: [{ id: 1, labWork: "", labWorkStatus: "" }],
      patientId: parseInt(param.id),
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const addNewCaseData = async () => {
    const patientLabData = await api.get(
      `/patient/${param.id}/labwork/list?labWorkStatus=incomplete`
    );

    let labWorks = [{ id: 1, labWork: "", labWorkStatus: "" }];
    if (patientLabData.data.status) {
      if (patientLabData.data.response.length > 0) {
        labWorks = patientLabData.data.response;
      }
    }
    if (patientCaseInfo.length > 0) {
      setPatientCaseInfo((prev) => {
        return [
          ...prev,
          {
            casePaperId: 0,
            familyDoctorName: "",
            complain: "",
            historyOff: "",
            onExamination: "",
            toothChart: [
              {
                toothChartType: "missing",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "roots",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "fill",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "fcc",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "bridge",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "implant",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "rct",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "caries",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
            ],
            treatmentAdvice: "",
            treatmentTodo: "",
            labWorks: labWorks,
            patientId: parseInt(param.id),
          },
        ];
      });
    } else {
      setPatientCaseInfo((prev) => {
        return [
          {
            casePaperId: 0,
            familyDoctorName: "",
            complain: "",
            historyOff: "",
            onExamination: "",
            toothChart: [
              {
                toothChartType: "missing",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "roots",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "fill",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "fcc",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "bridge",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "implant",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "rct",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
              {
                toothChartType: "caries",
                toothChartMode: "123",
                toothChartId: 0,
                upperRowLeft: "",
                upperRowRight: "",
                lowerRowLeft: "",
                lowerRowRight: "",
                casePaperId: 0,
              },
            ],
            treatmentAdvice: "",
            treatmentTodo: "",
            labWorks: labWorks,
            patientId: parseInt(param.id),
          },
        ];
      });
    }
  };

  const fetchDataPatientInfo = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/patient/${id}`);
      if (response.data.status) {
        setPatient(response.data.response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataPatientCaseInfo = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/patient/${id}/casepaper/list`);
      if (response.data.status) {
        if (response.data.response.length > 0) {
          setPatientCaseInfo((prev) => [...response.data.response]);
        } else {
          addNewCaseData();
        }
      } else {
        addNewCaseData();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataPatientInfo(param.id);
    fetchDataPatientCaseInfo(param.id);
    fetchDataPatientSummaryInfo(param.id);
  }, [param.id]);

  if (!patient) {
    return null;
  }
  const fetchDataPatientSummaryInfo = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/patient/${id}/summary/list`);
      if (response.data.status) {
        setPatientSummary(prev => ([...response.data.response, {
          "patientSummaryId": 0,
          "summaryDate": new Date(),
          "treatment": "",
          "medicine": "",
          "doctorId": 0,
          "patientId": parseInt(param.id),
          "doctorName": "",
          "dueAmount": 0,
          "treatmentStatus": "Incompleted"
        }]));
      } else {
        setPatientSummary(prev => ([{
          "patientSummaryId": 0,
          "summaryDate": new Date(),
          "treatment": "",
          "medicine": "",
          "doctorId": 0,
          "patientId": parseInt(param.id),
          "doctorName": "",
          "dueAmount": 0,
          "treatmentStatus": "Incompleted"
        }]));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      w={"100%"}
      h={"100vh"}
      bg={"white"}
    >
      <Box as="header">
        <Topbar title="Patients" />
      </Box>

      {/* Title Searchbar and Add Patient Button */}
      <Box
        m={"0 16px 16px"}
        pt={"16px"}
        borderRadius="8px 8px 0px 0px"
        boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
        bg={"white"}
      >
        <PatientStaticInfo patient={patient} />

        {patientCaseInfo.map((patientCase, index) => (
          <PatientCasePaper
            key={patientCase.casePaperId}
            patientCase={patientCase}
            patientId={parseInt(param.id)}
            addNewCaseData={addNewCaseData}
            index={index}
            lastCasePaper={index === patientCaseInfo.length - 1}
          />
        ))}

          <PatientSummary2
            mb={5}
            patientSummary={patientSummary}
            id={parseInt(param.id)}
            fetchDataPatientSummaryInfo={fetchDataPatientSummaryInfo}
          />
      </Box>
      <Box p={5} mb={10}></Box>
    </Box>
  );
}

export default PatientCase;
