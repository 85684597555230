import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const localizer = momentLocalizer(moment);

const events = [
  {
    title: 'Priya Sharma',
    start: new Date(2024, 6, 24, 9, 0),
    end: new Date(2024, 6, 24, 10, 0),
    id: 1,
    doctor: 'Dr. Shailesh D Parikh',
  },
  // Add more events here...
];

const CalendarComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({});
  const [events, setEvents] = useState([])
  const handleSelectSlot = (slotInfo) => {
    setNewEvent({
      start: slotInfo.start,
      end: slotInfo.end,
      title: '',
    });
    setModalIsOpen(true);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    events.push(newEvent);
    setModalIsOpen(false);
  };

  const moveEvent = ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);
  
    setEvents(nextEvents);
  };
  
  const resizeEvent = ({ event, start, end }) => {
    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
  
    setEvents(nextEvents);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Calendar
        selectable
        localizer={localizer}
        events={events}
        defaultView="week"
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        resizable
        style={{ height: '80vh' }}
      />
      <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal}>
        {selectedEvent ? (
          <div>
            <h2>{selectedEvent.title}</h2>
            <p>Doctor: {selectedEvent.doctor}</p>
            <p>
              Time: {moment(selectedEvent.start).format('h:mm A')} - {moment(selectedEvent.end).format('h:mm A')}
            </p>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        ) : (
          <div>
            <h2>New Appointment</h2>
            <input
              type="text"
              name="title"
              value={newEvent.title}
              placeholder="Patient Name"
              onChange={handleEventChange}
            />
            <button onClick={handleSave}>Save</button>
          </div>
        )}
      </Modal>
    </DndProvider>
  );
};

export default CalendarComponent;
