import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, InputGroup, InputRightElement, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("")
	const [errors, setErrors] = useState({});
	const [loader, setLoader] = useState(false)
	const [serverError, setServerError] = useState(false);


	const validate = () => {
		const newErrors = {};

		if (!email) {
			if (!email) newErrors.email = "Please enter Email ID";
		} else {
			newErrors.email = "";
		}
		return newErrors;
	};

	useEffect(() => {
		if (errors.email && email) {
			setErrors(prev => ({...prev, email: null}))
		}
	}, [errors, email])

	const onHandleSubmit = async (e) => {
		e.preventDefault();
		const validationErrors = validate();
		if (validationErrors.email) {
			setErrors(validationErrors);
			return;
		}

		setLoader(true);
		let API_RESPONSE = false;
		if (API_RESPONSE) {
			console.log(email);
		} else {
			setServerError("Username is not associated.");
		}
		setLoader(false);
	};

	return (
		<Flex
			flexDirection="column"
			justifyContent="space-between"
			alignItems="center"
			height={"100vh"}
		>
			<Image
				src={`${process.env.PUBLIC_URL}/images/home-page-left-design.png`}
				alt='home-page-left-design-logo'
				style={{ width: "303px", position: "fixed", zIndex: 1000, top: 0, left: 0}}
			/>
			<Image
				src={`${process.env.PUBLIC_URL}/images/home-page-right-design.png`}
				alt='home-page-right-design-logo'
				style={{ width: "387px", position: "fixed", zIndex: 1000, bottom: 0, right: 0,}}
			/>
			<Box>
			</Box>

			<Flex
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				width="294px"
			>
				<Box mb={8}>
					<Image
						src={`${process.env.PUBLIC_URL}/images/logo.png`}
						alt='logo'
						style={{ width: '290px', }}
					/>
				</Box>

				<Flex flexDir={"column"} alignSelf={"flex-start"} mb={5}>
					<Text as={"h2"} fontSize={32} fontWeight={700}>
						Reset password
					</Text>
					<Text fontSize={14} fontWeight={400} color={"#393939"}>
						Please enter your username associated with your account.
					</Text>
				</Flex>

				<form style={{width: "100%"}} onSubmit={onHandleSubmit}>
					<Stack
						width="100%"
						spacing={3}
						direction='column'
						mb={4}
					>
						<FormControl isInvalid={serverError || errors?.email} mb={2}>
							<FormLabel fontSize={14} fontWeight={600}>Email ID</FormLabel>
							<InputGroup>
							<Input
								border={"1px solid #D7D7D7"}
								type='email'
								placeholder="Please enter your email"
								autoComplete="off"
								value={email} 
								onChange={(e) => setEmail(e.target.value)} 
								/>
								{(serverError || errors?.email) && <InputRightElement>
									<Image
										src={`${process.env.PUBLIC_URL}/images/alert-circle.png`}
										alt='alert-circle-icon'
									/>
								</InputRightElement>}
								</InputGroup>
							{(serverError || errors?.email) && (
								<FormErrorMessage fontSize={14} color={"#F04438"}>{serverError ? serverError : errors.email}</FormErrorMessage>
							)}
						</FormControl>

					</Stack>

					<Button 
						isLoading={loader}
						loadingText='Submitting...' 
						_hover={{ opacity: "80%" }} 
						width={"100%"} 
						background={"#4C8BF5"} 
						color={"white"} 
						fontWeight={700} 
						fontSize={16}
						type="submit"
					>
						Submit
					</Button>

					<Flex justifyContent={"end"}>
						<ChakraLink textAlign={"right"} color={"#4C8BF5"} fontSize={14} fontWeight={500} as={ReactRouterLink}>
							Contact admin
						</ChakraLink>
					</Flex>
				</form>
			</Flex>

			<Flex justifySelf={"flex-end"} pb={4} alignItems={"end"}>
				<Text fontSize={12} fontWeight={400} mr={2}>
					Powered by 
				</Text>
				<Image
					src={`${process.env.PUBLIC_URL}/images/mounts-my.png`}
					alt='mounts-my-logo'
					style={{ width: '30px', marginRight: "8px"}}
				/>
				<Text fontSize={10} fontWeight={700}>
					MountsMy HealthCare
				</Text>
			</Flex>
		</Flex>
	);
};

export default ForgotPassword;
