import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './sidebar.css';
import { Image, Text } from '@chakra-ui/react';
import ListDashesIcon from '../icons/ListDashesIcon';
import PeopleIcon from '../icons/PeopleIcon';
import UsersIcon from '../icons/UsersIcon';
import CashRegisterIcon from '../icons/CashRegisterIcon';
import SettingIcon from '../icons/SettingIcon';
import LogoutIcon from '../icons/LogoutIcon';
import TableIcon from '../icons/TableIcon';
import { logout } from '../../store/auth/authSlice';
import { useDispatch } from 'react-redux';

const menuItems = [
  { name: 'Dashboard', path: '/dashboard', Icon: ListDashesIcon },
  {
    name: 'Patients', path: '/patients', Icon: PeopleIcon
    // submenu: [
    //   { name: 'All Patients', path: '/patients/all' },
    //   { name: 'New Patient', path: '/patients/new' }
    // ]
  },
  { name: 'Daysheet', path: '/daysheet', Icon: TableIcon },
  { name: 'Users', path: '/users', Icon: UsersIcon },
  { name: 'Transactions', path: '/transactions', Icon: CashRegisterIcon },
  { name: 'Settings', path: '/settings', Icon: SettingIcon },
  { name: 'Logout', path: "/login", Icon: LogoutIcon }
];

const Sidebar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const toggleSubmenu = () => setIsOpen(!isOpen);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const location = useLocation();

  const topMenuItems = menuItems.slice(0, menuItems.length - 2);
  const bottomMenuItems = menuItems.slice(menuItems.length - 2);

  return (
    <div className="sidebar" style={{width: sidebarOpen ? '240px' : '110px', minWidth: sidebarOpen ? '240px' : '100px'}}>
      <div>
        <div className="logo" style={{display: sidebarOpen ? 'block' : 'flex', justifyContent: 'space-around'}} onClick={() => setSidebarOpen(!sidebarOpen)}>
          {sidebarOpen ? (
            <Image src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" />
          ) :
          (
            <Image src={`${process.env.PUBLIC_URL}/images/logo-collapse.png`} height={'59px'} alt="Logo" />
          )
          }
        </div>
        <ul style={{ marginTop: "15px" }}>
          {topMenuItems.map(({ path, name, Icon, submenu }, index) => (
            <li key={index}>
              <NavLink
                to={path}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: location.pathname === path ? "4px" : "",
                  display: sidebarOpen ? 'block' : 'flex',
                  justifyContent: 'center'
                }}

              >
                <Icon color={location.pathname === path ? "white" : ""} />
                {sidebarOpen && (
                  <Text as={"span"} pl={"12px"} color={location.pathname === path ? "white" : "#383838"} fontSize={16} className="text">{name}</Text>
                )}
              </NavLink>
              {submenu && (
                <button onClick={toggleSubmenu} className="submenu-toggle">
                  {isOpen ? '▲' : '▼'}
                </button>
              )}
              {submenu && isOpen && (
                <ul className="submenu">
                  {submenu.map((subItem, subIndex) => (
                    <li key={subIndex}>
                      <NavLink to={subItem.path}>
                        {subItem.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* <ul>
        {bottomMenuItems.map(({ path, name, Icon }, index) => (
          <li key={index}>
            <NavLink to={path} onClick={() => {
              if (name === "Logout") {
                dispatch(logout())
              }
            }} style={{ width: "100%", padding: "6px", borderRadius: location.pathname === path ? "4px" : "", display: sidebarOpen ? 'block' : 'flex', justifyContent: 'center' }}>
              <Icon color={location.pathname === path ? "white" : ""} />
              {sidebarOpen && (
                <Text as={"span"} pl={"12px"} color={location.pathname === path ? "white" : "#383838"} fontSize={16} className="text">{name}</Text>
              )}
            </NavLink>
          </li>
        ))}
      </ul> */}
      {/* <div className="footer">
        <span>v0.1</span>
      </div> */}
    </div>
  );
};

export default Sidebar;
