import { Icon } from '@chakra-ui/react'

function Ellipse46({ color }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <circle cx="8" cy="8" r="7.5" fill={color} stroke="black" />
        </svg>
    )
}

export default Ellipse46